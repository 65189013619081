/* font family import */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  --body_bgcolor: #181818;
  --white: #fff;
  --black: #000;
  --primary_color: #00caba;
  --homecard_bg: #1e1e1e;
  --homecard_bgcolor: #1e1e1e;
  --orangeBtn_bg: #de6052;
  --violet: #ae9fe6;
  --violet_light: #8a70ca;
  --gradient_top: #68f1e9;
  --gradient_bottom: #d5f5f300;
  --gradient_border: linear-gradient(
    to right,
    var(--gradient_top),
    var(--gradient_bottom)
  );
  --benefit_gradientTop: #212121;
  --benefit_gradientBottom: #20202047;
  --benefit_cardGradient: linear-gradient(
    to bottom,
    var(--benefit_gradientTop),
    var(--benefit_gradientBottom)
  );
  --helpcard_gradient: linear-gradient(to bottom, #1e1e1e, #232323, #1e1e1e);
  --hr_connector: #d3f5f3;
  --walletcard_bg: #2d2d2d;
  --footer_gradientTop: #162827;
  --walletcard_shadow: #ececec;
  --stable_topGradient: #292929;
  --stable_bottomGradient: #222222;
  --stable_gradient: linear-gradient(
    to bottom,
    var(--stable_topGradient),
    var(--stable_bottomGradient)
  );
  --market_tabBg: #252525;
  --market_sellBtn: #3c3b3b;
  --market_paymentBtn: #626262;
  --offline_badge: #545454;
  --market_dropItem: #333333;
  --market_dropItem_hover: #4f4e4e;
  --market_sellTab: #d25045;
  --yellow: #fcda2b;
  --yellow_blur: #dfd46d;
  --red: #e21b1b;
  --market_tablecanva: #1f2020;
  --chat_bg: #090909;
  --send_border: #474747;
  --chat_box: #dbdbdb;
  --cmn_modal_closer: #303030;
  --purchasepop_inpBorder: #2e2e2e;
  --purchasepop_checkborder: #818181;
  --walletTransac_box: #1B1C1C;
  --walletTransac_badge: #122725;
  --walletTransac_greenBox: #242E2D;
  --walletTransac_border: #7C7979;
  --sidebar_headerBg: #393939;
  --sidebar_bodyBg: #2d2d2d;
  --switcher: #626262;
  --sidebar_footGrad: linear-gradient(
    to right,
    #C428FB,
    #FE6929
  );
  --border_change : #343434;
  --recomend_link : #498BEE;
  --profile_accord_border : #515151;
  --profile_garytext : #B7B7B7;
  --profile_faqbox : #323232;
  --profile_success : #03D43D;
  --profile_orange : #FF5B27;
  --security_card : #333333;
  --blur_primary : #303A39;
  --stpper_roundbg : #6A6A6A;
  --history_tablerow : #2C2C2C;
  --api_content : #282828;
  --swiper_bullet : #353535;
  --conver_hr : #666565;
  --placeholder : #666666;
  --speaker_border : #8C8C8C;
  --inquiry_blackBox : #161616;
  --inquiry_answerBox : #151515;
  --register_google : #606060;
  --register_linkHolder : #454545;
  --createOffer_borbot : #6F6F6F;
  --createOffer_offer : #D9D9D9;
  --createOffer_blur : #A3A3A3;
  --weight_four: 400;
  --weight_five: 500;
  --weight_six: 600;
  --weight_seven: 700;
  --weight_eight: 800;
  --weight_nine: 900;
  --small_font: 8px;
  --lower_fontsize: 10px;
  --font_eleven : 11px;
  --font_twelve: 12px;
  --font_thirteen: 13px;
  --font_fifteen: 15px;
  --font_sixteen: 16px;
  --font_eighteen: 18px;
  --font_twenty: 20px;
  --font_twentyfive: 25px;
  --higher_fontsize: 30px;
  --font_thirtyfive: 35px;
  --font_fourty: 40px;
  --font_fourtyfive: 45px;
  --font_fifty: 50px;
  --inter_font: "Inter", sans-serif;
  --stepper_roundtext : #c5c5c5;
}
[data-theme="dark"] {
  --body_bgcolor: #181818;
  --homecard_bg: #1e1e1e;
  --white: #fff;
  --black: #000;
  --white_footer: #fff;
  --walletcard_bg: #2d2d2d;
  --walletcard_shadow: #181818;
  --homecard_bgcolor: #1e1e1e;
  --stable_topGradient: #292929;
  --stable_bottomGradient: #222222;
  --stable_gradient: linear-gradient(
    to bottom,
    var(--stable_topGradient),
    var(--stable_bottomGradient)
  );
  --benefit_gradientTop: #212121;
  --benefit_gradientBottom: #20202047;
  --benefit_cardGradient: linear-gradient(
    to bottom,
    var(--benefit_gradientTop),
    var(--benefit_gradientBottom)
  );
  --helpcard_gradient: linear-gradient(to bottom, #1e1e1e, #232323, #1e1e1e);
  --homecard_bgfooter: #1e1e1e;
  --market_tabBg: #252525;
  --market_sellBtn: #3c3b3b;
  --market_paymentBtn: #626262;
  --market_dropItem: #333333;
  --market_dropItem_hover: #4f4e4e;
  --market_sellTab: #d25045;
  --yellow: #fcda2b;
  --yellow_blur: #dfd46d;
  --market_tablecanva: #1f2020;
  --red: #e21b1b;
  --chat_bg: #090909;
  --lite_gray: #353535;
  --lite_gray1: #373737;
  --lite_gray2: #323232;
  --lite_gray3: #414141;
  --lite_black: #212121;
  --little_lite_gray: #626262;
  --warning_msg: #ff5240;
  --lite_blue: #267bca;
  --alert: #ff9800;
  --lite_black1: #111;
  --accor_bg: #362f2f;
  --table_heading_bg: #292929;
  --cmn_modal_closer: #303030;
  --sidebar_headerBg: #393939;
  --sidebar_bodyBg: #2d2d2d;
  --walletTransac_box: #1B1C1C;
  --purchasepop_inpBorder: #2e2e2e;
  --walletTransac_greenBox: #242E2D;
  --border_change : #343434;
  --switcher: #626262;
  --recomend_link : #498BEE;
  --profile_accord_border : #515151;
  --profile_garytext : #B7B7B7;
  --profile_faqbox : #323232;
  --profile_success : #03D43D;
  --profile_orange : #FF5B27;
  --security_card : #333333;
  --blur_primary : #303A39;
  --stpper_roundbg : #6A6A6A;
  --stepper_roundtext : #c5c5c5;
  --history_tablerow : #2C2C2C;
  --api_content : #282828;
  --swiper_bullet : #353535;
  --conver_hr : #666565;
  --placeholder : #666666;
  --speaker_border : #8C8C8C;
  --inquiry_blackBox : #161616;
  --inquiry_answerBox : #151515;
  --register_google : #606060;
  --register_linkHolder : #454545;
  --blackList_border : #AB2300;
  --dark_pink : #E24ED7;
  --wait_contBox : #D45B4F;
  --browny : #FF4444;
  --storeReg_card : #313131;
  --coin_border : #5B5B5B;
  --kyc_radioTab : #44546A;
  --kyc_radioBorder : #1A3458;
  --kyc_radioBg : #ED7D31;
  --upbit_violet : #7030A0;
}
[data-theme="light"] {
  --body_bgcolor: #fff;
  --homecard_bg: #fff;
  --white: #343434;
  --black: #000;
  --white_footer: #fff;
  --walletcard_bg: #fafafa;
  --walletcard_shadow: #ececec;
  --homecard_bgcolor: #fafafa;
  --stable_topGradient: #fafafa;
  --stable_bottomGradient: #fafafa;
  --stable_gradient: linear-gradient(
    to bottom,
    var(--stable_topGradient),
    var(--stable_bottomGradient)
  );
  --benefit_gradientTop: #d1fffb;
  --benefit_gradientBottom: #fff;
  --benefit_cardGradient: linear-gradient(
    to bottom,
    var(--benefit_gradientTop),
    var(--benefit_gradientBottom)
  );
  --helpcard_gradient: linear-gradient(to bottom, #fafafa, #fafafa, #fafafa);
  --homecard_bgfooter: #1e1e1e;
  --market_tabBg: #fafafa;
  --market_sellBtn: #c9c9c9;
  --market_paymentBtn: #f3f3f3;
  --market_dropItem: #ededed;
  --market_dropItem_hover: #fff;
  --market_sellTab: #d25045;
  --yellow: #fcda2b;
  --yellow_blur: #dfd46d;
  --market_tablecanva: #fff;
  --red: #e21b1b;
  --chat_bg: #fafafa;
  --table_heading_bg: #eef4f7;
  --lite_gray: #e4eff3;
  --lite_gray1: #e4eff3;
  --lite_gray2: #eef5f7;
  --lite_gray3: #e4eff3;
  --lite_black: #eef5f7;
  --warning_msg: #ff5240;
  --lite_blue: #267bca;
  --alert: #ff9800;
  --lite_black1: #111;
  --accor_bg: #f3e2e2;
  --little_lite_gray: #c9c5c5;
  --cmn_modal_closer: #e5e5e5;
  --sidebar_headerBg: #e9e9e9;
  --sidebar_bodyBg: #f1f1f1;
  --walletTransac_box: #fafafa;
  --purchasepop_inpBorder: #e3e3e3;
  --walletTransac_greenBox: #addcd7;
  --border_change : #e3e0e0;
  --switcher: #e3e0e0;
  --recomend_link : #498BEE;
  --profile_accord_border : #d8d8d8;
  --profile_garytext : #B7B7B7;
  --profile_faqbox : #f2f2f2;
  --profile_success : #03D43D;
  --profile_orange : #FF5B27;
  --security_card : #fafafa;
  --blur_primary : #303A39;
  --stpper_roundbg : #b5b5b5;
  --stepper_roundtext : #4a4a4a;
  --history_tablerow : #d4d4d4;
  --api_content : #e4e4e4;
  --swiper_bullet : #353535;
  --conver_hr : #666565;
  --placeholder : #666666;
  --speaker_border : #8C8C8C;
  --inquiry_blackBox : #efefef;
  --inquiry_answerBox : #f4f4f4;
  --register_google : #606060;
  --register_linkHolder : #e0e0e0;
  --blackList_border : #AB2300;
  --dark_pink : #E24ED7;
  --wait_contBox : #D45B4F;
  --browny : #FF4444;
  --storeReg_card : #e1e1e1;
  --coin_border : #d1d1d1;
  --kyc_radioTab : #85a3cd;
  --kyc_radioBorder : #1A3458;
  --kyc_radioBg : #ED7D31;
  --upbit_violet : #7030A0;
}

/* common css */
.index_one{
  z-index: 1;
}
.custom_container{
  max-width: 90% !important;
  z-index: 20;
  position: relative;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.btn:hover,
.btn.show {
  color: var(--white);
}
.secondary_btn {
  border: 1px solid var(--primary_color);
  outline: none;
  color: var(--primary_color);
  font-size: var(--font_fifteen);
  font-family: var(--inter_font);
  background-color: transparent;
  padding: 7px 15px;
  border-radius: 5px;
  min-width: 100px;
  width: fit-content;
  font-weight: var(--weight_five);
}
.secondary_btn:hover {
  background-color: var(--primary_color);
  color: var(--black);
}
.primary_btn {
  border: 1px solid var(--primary_color);
  outline: none;
  background-color: var(--primary_color);
  color: var(--black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  font-family: var(--inter_font);
  border-radius: 5px;
  padding: 7px 15px;
  min-width: 100px;
  width: fit-content;
}
.primary_btn:hover {
  background-color: transparent;
  color: var(--primary_color);
}

.im_red_btn{
  border: 1px solid var(--red);
  outline: none;
  background-color: var(--red);
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  font-family: var(--inter_font);
  border-radius: 5px;
  padding: 7px 15px;
  min-width: 100px;
  width: fit-content;
}
.im_red_btn:hover {
  background-color: transparent;
  color: var(--red);
}
.primary_connetBtn {
  border: 1px solid var(--primary_color);
  outline: none;
  background-color: var(--primary_color);
  color: var(--black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  font-family: var(--inter_font);
  border-radius: 5px;
  padding: 7px 10px;
  width: fit-content;
  min-width: 45px;
  min-height: 39px;
  max-height: 39px;
}
.primary_connetBtn:hover {
  background-color: transparent;
  color: var(--primary_color);
}
.primary_connetBtn:hover .global_img.darkglobe {
  filter: invert(1);
}
.primary_connetBtn:hover .global_img.lightglobe {
  filter: invert(0);
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: lightgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary_color);
  border-radius: 10px;
}
.inter_font {
  font-family: var(--inter_font);
}
.home_higher_top {
  margin-top: 100px !important;
}
.home_medium_top {
  margin-top: 70px !important;
}
.home_higher_bottom {
  margin-bottom: 100px;
}
.home_medium_bottom {
  margin-bottom: 70px !important;
}
.padding_higher_top {
  padding-top: 100px !important;
}
.padding_medium_top {
  padding-top: 70px !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--homecard_bg);
  border: 1px solid var(--primary_color);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 6px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary_color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary_color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.error_text {
  margin: 0;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
/* .common_bg_holder{
  background-color: var(--body_bgcolor);
} */
body{
  background-color: var(--body_bgcolor);
}
.validationDangerHint{
  color: var(--red);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.cmn__adSwiper .swiper-button-prev,.cmn__adSwiper .swiper-button-next{
  color: var(--white) !important;
}
.cmn__adSwiper .swiper-button-prev:after,.cmn__adSwiper .swiper-button-next:after{
  font-size: var(--higher_fontsize) !important;
}
/* end of common css */

/* header css */
.header {
  /* background-image: linear-gradient(var(--homecard_bg),var(--homecard_bg)); */
  /* position: sticky;
    top: 0; */
  z-index: 2;
}
.header_navlinks {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  gap: 5px;
  padding-left: 0px;
  list-style-type: none;
}
.header_navlinks li,
.header_navlinks li a {
  padding-left: 0px;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  color: var(--white);
  text-decoration: none;
  font-family: var(--inter_font);
  white-space: nowrap;
}
.header_navlinks li a{
  padding: 5px 10px;
  border-radius: 5px;
}
.header_navlinks li a:hover,.header_navlinks li a.active{
  background-color: var(--primary_color);
  color: var(--white);
}
.header_links {
  text-decoration: none !important;
}
/* .header_linksBtn {
  border: none;
  outline: none;
  padding: 3px 7px;
  border-radius: 5px;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
} */
/* .header_linksBtn:hover {
  background-color: var(--primary_color);
  color: var(--black);
} */
/* .header_links.active,
.header_navlinks li:hover,
.header_navlinks li a:hover{
  color: var(--white);
  background-color: var(--primary_color);
} */
.header_theme_changer {
  font-size: var(--font_twenty);
  color: var(--black);
}
.theme_moon {
  position: absolute;
  left: 22%;
  top: 24%;
  font-size: var(--font_fifteen);
}
.theme_sun {
  position: absolute;
  right: 13%;
    top: 25%;
  font-size: var(--font_fifteen);
}
.header_canvas.offcanvas.offcanvas-end {
  background-color: var(--body_bgcolor);
  height: 100% !important;
}
.offcanva_logo {
  height: 45px;
  width: 100px;
}
.canva_header i {
  font-size: var(--font_twenty);
  color: var(--white);
  font-weight: var(--weight_seven);
}
.white_canva.header_canvas.offcanvas {
  background-color: var(--homecard_bg);
}
/* end of header css */

/* home page css */
.common_spacer {
  background-color: var(--body_bgcolor);
  padding: 30px;
  overflow: hidden;
}
.box_container {
  background-image: linear-gradient(var(--homecard_bg), var(--homecard_bg));
}
.home_banner {
  background-image: url("../images/homebanner.svg");
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner_content h1 {
  margin: 0;
  color: var(--black);
  font-size: var(--font_fifty);
  font-weight: var(--weight_seven);
  font-family: var(--inter_font);
  text-align: center;
}
.banner_content p {
  margin: 0;
  color: var(--black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  font-family: var(--inter_font);
  text-align: center;
}
.orange_btn {
  border: none;
  outline: none;
  color: var(--black);
  font-size: var(--font_thirteen);
  font-family: var(--inter_font);
  font-weight: var(--weight_five);
  background-color: var(--orangeBtn_bg);
  border-radius: 5px;
  padding: 7px 15px;
}
.orange_btn:hover{
  animation: jiggle 1s ease infinite;
}
@keyframes jiggle {
  0%{
    transform: scale(1);
  }
  50%{
    transform: scale(1.1);
  }
  100%{
    transform: scale(1);
  }
  
}
.home_bannerLeft h4 {
  margin: 0;
  color: var(--white);
  font-size: var(--font_twentyfive);
  font-weight: var(--weight_seven);
  font-family: var(--inter_font);
}
.home_bannerLeft h4 span {
  color: var(--primary_color);
}
.home_bannerLeft p {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
  font-family: var(--inter_font);
}
.violet_text {
  margin: 0;
  color: var(--violet);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_five);
  font-family: var(--inter_font);
  text-align: center;
}
.violet_text span {
  font-weight: var(--weight_nine);
}
.commitment_card {
  background-image: linear-gradient(
    to bottom,
    var(--homecard_bgcolor),
    var(--homecard_bgcolor)
  );
  border-radius: 15px;
  padding: 15px;
  position: relative;
  min-height: 220px;
  height: fit-content;
  max-height: fit-content;
  box-shadow: 4px 4px 10px 0px var(--homecard_bg);
}
.commitment_card_img {
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
  max-width: 100px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: -20%;
}
.commitment_card_title {
  margin: 0;
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
  font-family: var(--inter_font);
}
.commitment_card_desc {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  font-family: var(--inter_font);
}
.home_stepsHolder {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-image: linear-gradient();
  border-image-source: var(--gradient_border);
  background-image: linear-gradient(
    var(--gradient_top),
    var(--gradient_bottom)
  );
  padding: 25px;
  position: relative;
}
.right_rectangle {
  position: absolute;
  right: -11%;
  top: 45%;
  bottom: 45%;
}
.center_right_rectangle {
  position: absolute;
  right: -11%;
  top: 44%;
  bottom: 45%;
}
.center_left_rectangle {
  position: absolute;
  left: -10%;
  top: 44%;
  bottom: 45%;
}
.left_rectangle {
  position: absolute;
  left: -10%;
  top: 45%;
  bottom: 45%;
}
.home_hr_connector {
  margin: 0;
  border-color: var(--hr_connector);
  min-width: 100%;
  opacity: 1;
  position: relative;
  top: -9%;
}
.home_steps_img {
  /* max-height: 130px; */
}
.home_section_title {
  margin: 0;
  font-size: var(--font_eighteen);
  color: var(--white);
  font-weight: var(--weight_five);
  font-family: var(--inter_font);
}
.home_steps_hint {
  margin: 0;
  color: var(--primary_color);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.home_paxText {
  margin: 0;
  color: var(--white);
  font-size: var(--font_twentyfive);
  font-weight: var(--weight_eight);
}
.home_blueTitle {
  margin: 0;
  color: var(--primary_color);
  font-size: var(--higher_fontsize);
  font-weight: var(--weight_eight);
}
.enterprice_desc {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.enterprice_desc span {
  color: var(--primary_color);
  font-weight: var(--weight_six);
}
.enterprice_capsHint {
  margin: 0;
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_five);
  word-spacing: 1px;
}
.enterprice_capsHint::after {
  content: attr(data-text);
  color: var(--primary_color);
  font-weight: var(--weight_nine);
}
.walletCard_img {
  height: 150px;
  width: 150px;
  position: absolute;
  top: -30%;
  left: 30%;
  transform: translateX(5%);
}
.home_walletCard {
  background-color: var(--walletcard_bg);
  padding: 15px;
  border-radius: 15px;
  position: relative;
  min-height: 275px;
  height: fit-content;
  /* box-shadow: 6px 6px 10px 1px var(--walletcard_shadow); */
}
.walletCard_title {
  margin: 0;
  color: var(--primary_color);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_seven);
}
.home_walletCard span {
  font-weight: var(--weight_eight);
}
.home_businessSec {
  width: 100%;
  height: 300px;
  background-image: url("../images/business.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.business_hint {
  margin: 0;
  color: var(--black);
  font-size: var(--higher_fontsize);
  font-weight: var(--weight_nine);
}
.home_spel_imgHolder {
  height: 100px;
  width: 100px;
  background-color: var(--homecard_bg);
  border-radius: 50%;
  padding: 25px;
  z-index: 0;
}
/* .specialized_img{
  height: 60px !important;
  width: 60px !important;
  min-height: 60px !important;
  min-width: 60px !important;
  max-height: 60px !important; 
  max-width: 60px !important;
} */
.home_spel_hint {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.home_specialize_card_bottom {
  height: 10px;
  background-color: var(--primary_color);
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.home_specialize_card {
  border-radius: 10px;
  overflow: hidden;
  min-height: 230px;
  padding: 20px 20px 0px 20px;
  position: relative;
  cursor: pointer;
  width: 90%;
}
.home_spel_card_hovered,
.home_benefit_card_hoverer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
}
.home_specialize_card:hover .home_specialize_card_bottom {
  display: block;
}
.home_specialize_card:hover {
  background-image: linear-gradient(
    to bottom,
    var(--benefit_gradientTop),
    var(--body_bgcolor)
  );
  box-shadow: 0px 0px 2px 1px var(--benefit_gradientTop);
}
.home_specialize_lightHover.home_specialize_card:hover {
  background-image: linear-gradient(to bottom, var);
}
.home_specialize_card:hover .home_spel_imgHolder {
  background-color: var(--primary_color);
}
.benefits_img_holder {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: var(--homecard_bg);
  padding: 20px;
}
.benefits_img_holder img {
  min-width: 65px;
  max-width: 65px;
}
.home_benefits_card p {
  max-width: 60%;
}
.home_benefits_card {
  cursor: pointer;
}
.home_benefits_card:hover .benefits_img_holder {
  background-image: var(--benefit_cardGradient);
  box-shadow: 0px 0px 8px 0px var(--primary_color);
}
.home_benefits_card:hover p {
  color: var(--primary_color);
}
.home_helpCard {
  position: relative;
  background-image: var(--helpcard_gradient);
  padding: 30px;
  box-shadow: -1px -2px 3px -1px var(--primary_color);
  height: fit-content;
  min-height: 315px;
}
.helpCard_img {
  height: 150px;
  width: 150px;
  position: absolute;
  top: -25%;
  left: 42%;
  transform: translateX(-20%);
}
.home_helpCard p {
  max-width: 80%;
}
.home_arrow_steps {
  /* display: grid;
    grid-template-columns:repeat(auto-fill, minmax(170px, 1fr));
    gap: 20px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.home_singleStep {
  min-width: 200px;
}
.home_stepHint {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  max-width: 90%;
}
.home_blueArrowImg {
  max-width: 120px;
}
.banner_blurball {
  position: absolute;
  height: 500px;
  width: 500px;
  right: 0;
  top: 35%;
}
.middle_blurball {
  position: absolute;
  left: -11%;
  height: 900px;
  top: -5%;
}
.home_mob_steps {
}
.home_mob_singleStep {
  max-height: 150px;
  min-height: 150px;
  height: 150px;
  max-width: 150px;
  min-width: 150px;
  width: 150px;
}
.mob_stepImg {
  min-width: 100px;
  max-width: 100px;
}
.home_steps_mobhint {
  min-width: 50%;
  max-width: 50%;
}
.stable_boxContainer {
  background-image: var(--stable_gradient) !important;
}
.home_fishblurball {
  position: absolute;
  left: -30%;
  height: 650px;
  width: 500px;
  top: 10%;
  opacity: 0.8;
}
.home_fishblurball_right {
  position: absolute;
  right: -30%;
  height: 650px;
  width: 500px;
  bottom: -17%;
  opacity: 0.4;
}
/* end of home page css */

/* footer css */
.footer {
  background-image: linear-gradient(
    to bottom,
    var(--footer_gradientTop),
    var(--homecard_bgfooter)
  );
}
.footer_linksTitle {
  margin: 0;
  color: var(--white_footer);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_seven);
}
.footer_links {
  padding-left: 0px;
  list-style-type: none;
  color: var(--white);
}
.footer_links li,
.footer_links li a {
  color: var(--white_footer);
  text-decoration: none;
  font-size: var(--font_thirteen);
  width: fit-content;
}
.footer_links li:hover,
.footer_links li a:hover {
  color: var(--primary_color);
}
.footer_gridHolder {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 20px;
  border-bottom: 1px solid gray;
}
.footer_copyright_holder p {
  color: var(--white_footer);
  margin: 0;
  font-size: var(--font_sixteen);
  font-weight: var(--weight_four);
}
/* end of footer css */

/* market css */
.market_select_label {
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_five);
}
.market_select_component {
  padding: 8px;
  background-color: var(--homecard_bg);
  color: var(--white);
  font-size: var(--font_thirteen);
  font-family: var(--inter_font);
  font-weight: var(--weight_five);
}

.market_select_component:hover {
  background-color: var(--primary_color);
  color: var(--white);
}
.market_buytab {
  background-color: var(--market_tabBg);
  cursor: pointer;
  min-height: 140px;
}
.market_buytab.active {
  background-color: var(--primary_color);
}
.market_buytab.activeSell {
  background-color: var(--market_sellTab);
}
.market_buytab h5 {
  margin: 0;
  font-size: var(--font_twenty);
  color: var(--white);
  font-weight: var(--weight_eight);
}
.market_buytab p {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  line-height: 1;
}
.market_tab_button {
  border: none;
  outline: none;
  width: 100%;
  padding: 7px 10px;
  background-color: var(--market_tabBg);
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_six);
}
.market_tab_sellbutton {
  border: none;
  outline: none;
  width: 100%;
  padding: 7px 10px;
  background-color: var(--market_sellBtn);
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_six);
}
.primary_sec {
  border: 1px solid var(--primary_color);
  outline: none;
  background-color: var(--primary_color);
  color: var(--black);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  font-family: var(--inter_font);
  border-radius: 5px;
  padding: 7px 15px;
  min-width: 100px;
  width: fit-content;
}
.market_filter_btn {
  border: none;
  outline: none;
  background-color: var(--benefit_gradientBottom);
  border-radius: 7px;
  color: var(--white);
  padding: 5px 7px;
}


.vendor_badge {
  border: 1px solid var(--primary_color);
  border-radius: 0px 5px 5px 5px;
  font-size: var(--lower_fontsize);
  color: var(--white);
  padding: 3px;
  background-color: var(--stable_bottomGradient);
  position: absolute;
  bottom: -30%;
  left: 50px;
  width: fit-content;
  height: fit-content;
}
.vendor_badge img {
  height: var(--font_fifteen);
  width: var(--font_fifteen);
}
.user_activity_online_indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--primary_color);
  border: 1px solid var(--primary_color);
  position: absolute;
  right: 0;
  top: 0;
}
.user_activity_offline_indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--offline_badge);
  border: 1px solid var(--primary_color);
  position: absolute;
  right: 0;
  top: 0;
}
.user_activity_holder {
  width: fit-content;
}
.user_country_flag {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
}
.market_tbluser_name {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.market_payment_btn {
  border: none;
  outline: none;
  cursor: default !important;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
  padding: 7px;
  background-color: var(--market_paymentBtn);
  min-width: 180px;
}
.market_table_intvalues {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  white-space: nowrap;
}
.market_table_intvalues span{
  color: var(--primary_color);
}
.market_table_blur{
  color: var(--createOffer_blur);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.market_sort_dropdown button {
  border: none;
  outline: none;
  background-color: var(--benefit_gradientBottom);
  border-radius: 7px;
  color: var(--white);
  padding: 7px 10px;
  width: fit-content;
}
.market_sort_dropdown button:hover,
.market_sort_dropdown button:focus,
.market_sort_dropdown button:active,
.market_sort_dropdown button:focus-visible {
  background-color: var(--benefit_gradientBottom);
  box-shadow: none;
}
.market_sort_dropdown.dropstart button::after,
.market_sort_dropdown.dropstart button::before {
  display: none;
}
.market_sort_dropdown.dropstart .dropdown-item-text {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
.market_sort_dropdown.dropstart .dropdown-menu {
  background-color: var(--market_dropItem);
}
.market_sort_dropdown.dropstart .dropdown-item {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.market_sort_dropdown.dropstart .dropdown-item:hover {
  background-color: var(--market_dropItem_hover);
}
.market_progressBtn {
  border: 1px solid var(--yellow);
  outline: none;
  background-color: var(--yellow);
  color: var(--black);
  width: fit-content;
  padding: 5px 8px;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  min-width: 120px;
  max-width: 120px;
  min-height: 50px;
}
.market_progressBtn:hover {
  border: 1px solid var(--yellow);
  background-color: transparent;
  color: var(--yellow);
}
.market_sellBtn {
  border: 1px solid var(--market_sellTab);
  outline: none;
  background-color: var(--market_sellTab);
  color: var(--white);
  width: fit-content;
  padding: 5px 8px;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  min-width: 120px;
  max-width: 120px;
  min-height: 50px;
}
.market_sellBtn:hover {
  border: 1px solid var(--market_sellTab);
  background-color: transparent;
  color: var(--market_sellTab);
}
.market_coinname_select .market_coinname_img {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 63%;
  left: 5%;
}
.market_coinname_select .market_coinname_img.conver_coinName_img{
  top: 56%;
}
.market_coinname_img {
  height: 20px;
  width: 20px;
}
.market_coinname_select .css-qbdosj-Input,
.market_coinname_select .css-qbdosj-Input input {
  color: var(--white) !important;
}
.market_drophint {
  height: 10px;
  width: 10px;
  position: absolute;
  top: 70%;
  right: 5%;
}
.market_tv_drophint{
  top: 55%;
}
.table_cointType {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.market_canva_table{
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.market_canva_header h6 {
  margin: 0;
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
}
.market_canva_header i {
  margin: 0;
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
  cursor: pointer;
}
.market_tv_title {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
.market_tablerView {
  background-color: var(--walletcard_bg);
  height: fit-content;
  min-height: 115px;
}
.mt_selects_label{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  /* white-space: nowrap; */
  min-width: 85px;
}
.mt_selects_holder{
  background-color: var(--walletcard_bg);
  padding: 0px 35px 15px;
}
.mt_transaction_counter{
  background-color: var(--walletTransac_box);
}
.mt_usdt_leftHead{
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}

.mt_date_count_title{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.mt_tiny_datetimer{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  white-space: nowrap;
}
.mt_whitecopy_img{
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.mt_whitecopy_img_invert{
  height: 18px;
  width: 18px;
  cursor: pointer;
  filter: invert(1);
}
.mt_coin_batch{
  border: none;
  outline: none;
  background-color: var(--walletTransac_badge);
  color: #fff;
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);

}
.mt_coin_batch img{
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  max-height: 25px;
  max-width: 25px;
}
.mt_redgradient_box{
  background-image: linear-gradient(to right,var(--market_sellTab),var(--purchasepop_inpBorder) );
  padding-left: 35px !important;
  min-height: 70px;
}
.mt_greengradient_box{
  background-image: linear-gradient(to right,var(--walletTransac_greenBox),var(--walletTransac_greenBox) );
  min-height: 70px;
}
.mt_seller_personImg{
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  border-radius: 50px;
}
.mt_seller_transDtls,.mt_seller_flagHolder{
  border: 1px solid var(--border_change);
  width: fit-content;
  min-height: 50px;
  max-height: 50px;
}
.mt_seller_transDtls{
  flex: 1;
}
.mt_seller_flagHolder{
  border-left: none !important;
}
.mt_seller_flagHolder img{
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
}
.mt_Seller_indication_hint{
  height: 25px;
  width: 25px;
  background-color: var(--primary_color);
}
.mt_seller_bank_remit{
  border: 1px solid var(--border_change);
  outline: none;
  color: var(--primary_color);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  background-color: transparent;
  height: 80px;
  width: 100%;

}
.mt_seller_grayBtn{
  border: none;
  outline: none;
  background-color: var(--market_paymentBtn);
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.mt_seller_transact_side,.mt_seller_quantity_side{
  padding: 30px;
}
.secondary_btn_div{
  border: 1px solid var(--walletTransac_border);
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  width: fit-content;
  padding: 7px 25px;
}
.mt_seller_qunalabels_span{
  color: var(--primary_color);
  font-weight: var(--weight_six);
}
.mt_seller_quanlabels_bold{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.mt_seller_quanlabels_primary{
  margin: 0;
  color: var(--primary_color);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.mt_seller_qunalabels_whitespan{
  margin: 0;
  color: var(--white);
  font-weight: var(--weight_six);
  font-size: var(--font_thirteen);
}
.mt_seller_trans_input{
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.mt_seller_transdtls_hint{
  margin: 0;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.mt_deposit_div{
  width: 100%;
  height: 100%;
  background-color: var(--primary_color);  
  display: flex;
  justify-content: center;
}
.mt_remitt_div{
  width: 100%;
  height: 100%;
  background-color: var(--market_sellTab);  
  display: flex;
  justify-content: center;
}
.mt_deposit_div h6,.mt_remitt_div h6{
  margin: 0;
  color: var(--black);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_seven);
  font-family: var(--inter_font);
}
.mt_timermid_countdown span{
  color: var(--white);
  font-size: var(--font_fifty);
  font-family: var(--inter_font);
  font-weight: var(--weight_eight);
}
.mt_transact_received_msg{
  background-color: var(--primary_color) !important;
}
.mt_transact_sendmsg{
  background-color: var(--market_sellTab) !important;
}
.mt_transac_curveRight{
  border-top-color: var(--market_sellTab) !important;
}
.mt_transact_curveLeft{
  border-top-color: var(--primary_color) !important;
}
.market_canvatable_title{
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.market_canvatable_hint{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.market_canavtable_img{
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  max-height: 25px;
  max-width: 25px;
  object-fit: contain;
}
.market_canvatable_singleBox{
  background-color: var(--walletcard_bg);
}
.mt_transactionGuideTitle{
  color: var(--dark_pink);
  font-size: var(--font_twenty);
  font-weight: var(--weight_six);
}
.mt_guideBlur{
  position: absolute;
    top: -60%;
    left: -30%;
    width: 1100px;
    height: 810px;
}
.mt_transactionGuidOl,.mt_transactionGuidOl li{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);

}
.si_msgBox_waitingCont{
  background-color: var(--wait_contBox);
 
}
.msgBox_waitingContHolder{
  position: absolute;
  width: 93.5%;
  bottom: 10%;
}
.waitingCont_title{
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_six);
}

.waitingCont_desc{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.waitingCont_hint{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.mk__tripleTab{
  background-color: var(--market_tabBg);
}
.mk__tripleTabTitle{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_six);
}
.mk__tripleTabHint{
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_four);
}
.mk__tripleTab{
  min-height: 125px;
}
.mk__coloredTaberRed{
  color: var(--market_sellTab);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
.mk__coloredTaberRed.active{
  color: var(--white);
}
.mk__coloredTaberPrimary{
  color: var(--primary_color);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
.mk__coloredTaber{
  cursor: pointer;
}
.mk__coloredTaberPrimary.active{
  color:var(--white);
}
.mk__coloredTaber.active{
  background-color: var(--market_sellTab);
}
.mk__coloredTaber.mk_colorTaberPrimary{
  background-color: var(--primary_color);
}
.mk__chooseCoinTitle{
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_five);
}
.mk__coinGridImg{
  height: 25px;
  width: 25px;
}
.mk__coinGridName{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.mk__coinGridSingle{
  border: 1px solid var(--coin_border);
  padding: 5px;
  cursor: pointer;
}
.mk__coinGrid{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 20px;
}
.activeCoin.mk__coinGridSingle{
  border-color: var(--primary_color);
}
.mk__contentTaber{
  background-color: var(--market_tabBg);
  min-height: 80px;
}
.span__redText{
  color: var(--market_sellTab);
}
.mk__searchGlass{
  height: 15px;
  width: 15px;
}
.mk__inputter{
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  max-width:43%;
  min-width: 43%;
  width:43%;
}
.mk__inputSelectHolder{
  border: 1px solid var(--coin_border);
}
.mk__quesIcon{
  color: var(--coin_border);
}
.mk__flagSelect.cmn_flag_select{
  border-color: var(--coin_border);
}
.mk__custRadio input{
  border: 5px solid var(--kyc_radioBorder) !important;
  outline: none !important;
  box-shadow: none !important;
  height: 40px;
  width: 40px;
  background-color: transparent;

}
.mk__custRadio .form-check-input:checked{
  background-color: var(--kyc_radioBg); 
  background-size: 100% 100%;
  background-position: center;
  background-image: none !important;
}
.mk__radioTaber{
  border: 2px solid var(--coin_border);
  min-height: 100px;
  cursor: pointer;
}
.mk__radioTaber.active{
  background-color: var(--kyc_radioTab);
  border-color: var(--kyc_radioTab);
}
.mk__currentPriceLabel{
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.mk__currentPriceHint{
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_four);
}
.mk__currentPriceImg{
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
}
.mk__currentPriceSingle{
  border: 1px solid var(--coin_border);
}
.mk__currentPriceGrid{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
}
.mk__inputsTitle{
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.mk__inputsTitle span{
  color: var(--primary_color);
}
.mk__violetText{
  color: var(--upbit_violet);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.mk__incDecHolder{
  border: 1px solid var(--coin_border);
  min-height: 36px;
}
.mk__transBtn{
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  cursor: pointer;
  padding: 5px 15px;
}
.mk__transBtnPercent{
  border-left: 1px solid var(--coin_border);
  min-height: 36px;
}
.mk__incDecInput{
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
  max-width: 35%;
}
.mk__quantityInp.mk__incDecInput{
  max-width: 65%;
}
.mk__incDecInput.mk__inpWidthAligner{
  max-width: 62%;
}
.mk__incDec{
  max-width: 76%;
  border-right: 1px solid var(--coin_border);
  height: 36px;
}
.mk__incDecWidAligner.mk__incDec{
  max-width: 85%;
}
.mk__leftBorder{
  border-left: 1px solid var(--coin_border);
}
.mk__timeInputter{
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
  max-width: 50%;
}
.mk__timerMinutes{
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.mk__timerChanger{
  border-right: 1px solid var(--coin_border);
  min-height: 36px;
  max-width: 70%;
}
.mk__timerHolder{
  border: 1px solid var(--coin_border);
  min-height: 36px;
}
.mk__percentHolder{
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.mk__sellRegBtn{
  border: 1px solid var(--market_sellTab);
  outline: none;
  color: var(--white);
  background-color: var(--market_sellTab);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
.mk__sellRegBtn:hover{
  background-color: transparent;
  color: var(--market_sellTab);

}
.mk__buyPrimaryBtn{
  border: 1px solid var(--primary_color);
  outline: none;
  color: var(--white);
  background-color: var(--primary_color);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
.mk__buyPrimaryBtn:hover{
  background-color: transparent;
  color: var(--primary_color);

}
.mk__inputsTitle span.mk__spanSell{
  color: var(--primary_color);
}
.mk__inputsTitle span.mk__spanBuy{
  color: var(--market_sellTab);
}
/* end of market css */
/* register css */
.reg_hr{
  height: 1px;
  width: 100%;
  /* background-image: var(--stable_gradient); */
  background-color: var(--conver_hr);
}
.reg_or{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  background-image: var(--stable_gradient);
  position: absolute; 
     left: 45%;
  right: 45%;
  top: -10px;
}
.reg_google_auth{
  background-color: var(--register_google);
  border: 1px solid var(--register_google);
  padding: 10px 0px;
  cursor: pointer;
}
.reg_google_auth:hover{
  background-color: transparent;
  border: 1px solid var(--register_google);
}
.reg_google_auth:hover .reg_google_imger{
  filter: invert(1);
}
.reg_google_auth:hover .reg_google_imgerdark{
  filter: invert(0);
}
.reg_googleText.black_text{
  color: #fff;
}
.reg_google_auth:hover .reg_googleText.black_text{
  color: #000;
}
.reg_google_auth img{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20%;
  left: 5%;
}
.reg_googleText{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_six);
}
.reg_googleText.white_text{
  color: #fff;
}

.reg_primaryText{
  color: var(--primary_color);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  cursor: pointer;
}
.reg_qrcode_btn{
  border: 1px solid var(--register_google);
  outline: none;
  background-color: var(--register_google);
  color: #fff;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  white-space: nowrap;
}
.reg_qrcode_btn img{
  height: 20px;
  width: 20px;
}
.reg_qrcode_holder{
  width: fit-content;
}
.reg_qrcode_img{
  opacity: 0.1;
}
.reg_qrcoder{
  height: 150px;
  width: 150px;
  min-height: 150px;
  min-width: 150px;
  max-height: 150px;
  max-width: 150px;
}
.reg_qrcoder_filter{
  filter: invert(1);
}
.reg_mobqr{
  height: 150px;
  width: 150px;
  min-height: 150px;
  min-width: 150px;
  max-height: 150px;
  max-width: 150px;
}
.reg_rightTtl{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.reg_stats_shower{
  color: var(--white);
  font-size: var(--font_twentyfive);
  font-weight: var(--weight_five);
}
.reg_url_holder{
  background-color: var(--register_linkHolder);
}
.reg_url_holder img{
  height: 20px;
  width: 20px;
}
.reg_url_holder p{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.creacteacc_title{
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
.createacc_checklabel{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_six);
}
.createacc_checklabel a{
  color: var(--primary_color);
}
.mailver_hint{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.mailver_hint.mailver_weighter{
  font-weight: var(--weight_seven);
}
.mailver_inpBtb{
  color: var(--primary_color);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
  cursor: pointer;
}
.mailver_inputHolder input{
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.mailver_inputHolder{
  border: 1px solid var(--border_change);
}
.mailver_backBtn{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  position: absolute;
  top: 5%;
  left: 2%;
  cursor: pointer;
}
.mailver_eye{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
  cursor: pointer;
}
.register_dropDown button{
  background-color: transparent !important;
  border: 1px solid var(--border_change) !important;
  outline: none;
  box-shadow: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  width: 100%;
  text-align: start !important;
  font-family: var(--inter_font);

}
.register_dropdown_holder .dropdown-toggle::after{
  position: absolute;
  right: 3%;
  top: 45%;
}
.register_dropdown_menu{
  background-color: var(--stable_topGradient);
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
}
.register_dropdown_item{
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.register_dropdown_item:hover{
  background-color: var(--primary_color);
  color: var(--black);
}
/* end of register css */
/* login css */
.login_box_container.box_container {
  background-image: url("../images/login_bg.png"),
    linear-gradient(var(--homecard_bg), var(--homecard_bg));
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.inner_fishblur {
  position: absolute;
  height: 400px;
  top: 40%;
  left: -10%;
  opacity: 0.7;
}
.login_cont_holder h6 {
  margin: 0;
  color: var(--white);
  font-size: var(--font_twentyfive);
  font-weight: var(--weight_seven);
}
.login_cont_holder p {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.login_selector {
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.login_via {
  width: 40%;
  padding: 7px;
  background-color: transparent;
  cursor: pointer;
}
.login_via.active {
  background-color: var(--primary_color);
}
.login_via p {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.login_form_holder {
  background-image: var(--stable_gradient);
}
.login_single_input label {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.primary_input {
  border: 1px solid var(--border_change);
  outline: none;
  background-color: var(--stable_bottomGradient);
  color: var(--white);
  font-size: var(--font_thirteen);
}
.login_check_holder .form-check-input.is-valid {
  border-color: var(--border_change) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.login_check_holder .form-check-input.is-valid:checked,
.login_check_holder .was-validated .form-check-input:valid:checked {
  border-color: var(--primary_color) !important;
  background-color: var(--primary_color) !important;
  box-shadow: none !important;
}
.login_check_holder form {
  width: fit-content;
}
.login_check_label {
  margin: 0;
  font-size: var(--font_thirteen);
  color: var(--white);
  font-weight: var(--weight_four);
}
.login_form_bottom a {
  margin: 0;
  color: var(--primary_color);
  font-size: var(--font_thirteen);
  text-decoration: underline;
  font-weight: var(--weight_four);
}
.login_otp_holder input {
  width: 40px !important;
  height: 40px;
  border: 1px solid var(--border_change);
  outline: none;
  background-color: var(--stable_bottomGradient);
  color: var(--white);
  border-radius: 5px;
  margin-left: 10px;
}
.login_otp_holder span{
  display: none;
}
.login_otp_holder input:first-child {
  margin-left: 0px;
}
.login_otpOption_holder span {
  color: var(--white);
  width: fit-content;
  text-align: end;
  font-family: var(--inter_font);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.login_resendOTP {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--white);
  text-decoration: underline;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.login_resendOTP:hover {
  color: var(--primary_color);
}
.primary_btn.disabled {
  opacity: 0.5;
}
.primary_btn.disabled:hover {
  opacity: 0.5;
  background-color: var(--primary_color);
  color: var(--black);
}
/* end of login css */
/* store css */
.storepage_card {
  background-color: var(--walletcard_bg);
  border: 1px solid var(--border_change);
}
.sc_coinName {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.sc_coinSym {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_six);
}
.sc_labeltext {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.sc_coinValue {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.store_cardImg {
  height: 55px;
  width: 55px;
  min-height: 55px;
  min-width: 55px;
  max-height: 55px;
  max-width: 55px;
  object-fit: contain;
}
.store_top_search {
  border: 1px solid var(--border_change);
  background-color: var(--stable_bottomGradient);
  padding: 7px 10px;
  height: 40px;
}
.store_searchglass {
  height: 14px;
  width: 14px;
}
.store_top_search input {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: var(--font_thirteen);
  color: var(--white);
  font-weight: var(--weight_four);
  max-width: 80%;
}
.sd_seller_dtls h6 {
  margin: 0;
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
.sd_seller_name {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.sd_product_dtls p {
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.sd_product_dtls span {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  position: relative;
  top: -8px;
}
.sd_hinter {
  margin: 0;
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_five);
}
.sd_card_prodImg {
  height: 100px;
  width: 100px;
  min-height: 100px;
  min-width: 100px;
  max-height: 100px;
  max-width: 100px;
  object-fit: contain;
  border: 1px solid var(--border_change);
}
.sd_favourite_holder{
  position: absolute;
  right: 5%;
  width: fit-content;
}
.sd_favourite_holder i {
  color: var(--red);
  font-size: var(--font_eighteen);
  cursor: pointer;
}
.store_detail_card {
  background-color: var(--walletcard_bg);
  border: 1px solid var(--border_change);
}
.si_desc_title {
  margin: 0;
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_six);
}
.si_blue_text {
  margin: 0;
  color: var(--primary_color);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
  cursor: pointer;
}
.si_desc_sub_title {
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_five);
}
.si_desc {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.si_tableScroller{
  max-height: 500px;
  overflow-y: auto;
}
/* .si_tableScroller::-webkit-scrollbar{
  width: 0;
  height: 0;
} */
.si_table {
  border: 1px solid var(--border_change);
  overflow: auto;
  max-height: 600px;
}

.si_table_prodImg {
  height: 100px;
  width: 100px;
  border: 1px solid var(--border_change);
}
.table_triple_grad {
  background-image: linear-gradient(to right, #222222, #c4c4c4, #222222);
  height: 2px;
  width: 90%;
  left: 5%;
  position: absolute;
  top: 60px;
}
.table_triple_grad_light {
  background-image: linear-gradient(to right, #ebebeb, #3e3e3e, #eeeeee);
  height: 2px;
  width: 90%;
  left: 5%;
  position: absolute;
  top: 60px;
}
.table_title_grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
/* .table_title_single_grid {
  min-width: 180px;
} */
.si_gird_title {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
.si_gird_hint {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.si_activity_online_indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--primary_color);
  border: 1px solid var(--primary_color);
}
.si_activity_offline_indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--offline_badge);
  border: 1px solid var(--primary_color);
  position: absolute;
  right: 0;
  top: 0;
}
.si_userName {
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.si_lastseen_indicator {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.si_forwardbtns_holder .secondary_btn {
  min-width: fit-content;
}
.si_yellow_text {
  margin: 0;
  color: var(--yellow_blur);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.si_info_holder img {
  height: 18px;
  width: 18px;
}
.table_triple_grad_rel {
  background-image: linear-gradient(to right, #222222, #c4c4c4, #222222);
  height: 2px;
  width: 80%;
  position: relative;
  left: 10%;
}
.table_triple_grad_rel_light {
  background-image: linear-gradient(to right, #ebebeb, #3e3e3e, #eeeeee);
  height: 2px;
  width: 80%;
  position: relative;
  left: 10%;
}
.si_chat_holder {
  background-color: var(--chat_bg);
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.si_sendImg_holder {
  padding: 10px 10px;
  background-color: transparent;
  border: 1px solid var(--send_border);
  width: fit-content;
  cursor: pointer !important;
}
.si_sendImg {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  max-height: 20px;
  max-width: 20px;
}
.si_chat_fileInput {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  cursor: pointer !important;
}
.si_chat_input {
  border: 1px solid var(--send_border);
  padding: 10px;
  background-color: transparent;
  font-size: var(--font_fifteen);
  color: var(--white);
  flex: 1;
  min-width: 10%;
}
.si_chat_input:focus-visible {
  outline: none !important;
}
.si_send_btn {
  border: 1px solid var(--send_border);
  outline: none;
  font-size: var(--font_fifteen);
  color: var(--white);
  background-color: transparent;
  padding: 10px 30px;
  width: fit-content;
  font-weight: var(--weight_five);
}
.si_send_btn_icon {
  border: 1px solid var(--send_border);
  outline: none;
  font-size: var(--font_fifteen);
  color: var(--white);
  background-color: transparent;
  padding: 10px 15px;
  width: fit-content;
  font-weight: var(--weight_five);
}
.si_send_btn:hover,
.si_send_btn_icon:hover {
  background-color: var(--primary_color);
  font-weight: var(--weight_five);
  color: var(--black);
}

.si_msgcnt_holder {
  background-color: var(--chat_box);
  width: fit-content !important;
  max-width: fit-content !important;
  border-radius: 5px 5px 0px 5px;
}
.si_msgcnt_holderLeft {
  background-color: var(--chat_box);
  width: fit-content !important;
  max-width: fit-content !important;
  border-radius: 5px 5px 5px 0px;
}
.si_msgcnt_holder p {
  margin: 0;
  color: var(--black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.si_msgcnt_holderLeft p {
  margin: 0;
  color: var(--black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.si_received_msg,
.si_send_msg {
  max-width: 80%;
}

.si_received_msgtime {
  margin: 0;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.si_msgbox_curve {
  width: 0px;
  border-top: 15px solid var(--chat_box);
  border-left: 20px solid transparent;
  position: absolute;
  bottom: -12px;
  right: 0;
}
.si_msgbox_curveLeft {
  width: 0px;
  border-top: 15px solid var(--chat_box);
  border-right: 20px solid transparent;
  position: absolute;
  bottom: -11px;
  left: 0;
}
.si_chatbox_scroller {
  height: 400px;
  max-height: 400px;
  overflow-y: auto;
}
.sd_store_coingImg.market_coinname_img{
  top:25%;
}
.market_drophint.sd_store_drophint{
  top: 40%;
}
.market_coinname_img.sd_store_searchImg{
  top:35%;
  left: 7%;
  height: 14px;
  width: 14px;
}
.cmn_flag_select button{
  border: none;
  outline: none;
  color: var(--white);
  background-color: transparent;
  font-size: var(--font_thirteen) !important;
  padding: 0;
  height:38px;
  overflow: hidden !important;
  text-overflow: ellipsis  !important;

}
.cmn_flag_select{
  border: 1px solid var(--border_change);
  background-color: transparent;
}
.cmn_flag_select button span svg{
  height: 20px !important;
  width: 20px !important;
}
.cmn_flag_select button span{
  max-width: 100%;
  text-overflow: ellipsis !important;
}
.cmn_flag_select button::after,.cmn_flag_select button[aria-expanded="true"]::after{
  display: none;
}
.st_store_yellowbtn{
  border: none;
  outline: none;
  background-color: var(--yellow);
  color: var(--black);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  height: 40px;
}
.sd_bannerer{
  min-width: 100%;
  height: auto;
  min-height: 200px;
  max-height: 400px;
  object-fit: cover;
}
.heig_primary_btn.primary_btn{
  height: 40px;

}
.si_mainprod_img{
  border: 1px solid var(--border_change);
}
.storeReg__certifyBadge{
  border: 1px solid var(--border_change);
  padding: 10px 0px; 
}
.stoerReg__certifyStatus{
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_four);
}
.storeReg__primaryBtn.primary_btn{
  min-width: 65px;
  padding: 7px 10px;
}

.storeReg__subLabel{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.storeReg__primaryInp{
  width: 100%;
}

.storeReg__selectCoin.sd_store_searchImg.market_coinname_img{
  height: 20px;
  width: 20px;
  top: 24%;
  left: 2%;
}
.storeReg__producTaber{
  background-color: var(--stable_topGradient);
  width: fit-content;
}
.ss_searchGlass{
  height: 15px;
  width: 15px;
}
.ss_staticSearch{
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
  max-width: 90%;
  width: 90%;
}
.ss__staticSearchHolder{
  border: 1px solid var(--border_change);
  overflow: hidden;
}
.ss_yellowBtn{
  padding: 0px 20px;
}
.ss_gridRedValue{
  color: var(--browny);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_five);
}
.ss_gridPrimeValue{
  color: var(--primary_color);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_five);
}
.ss_whiteOulineBtn{
  border: 1px solid var(--white);
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  height: 40px;
  white-space: nowrap;
}
.ss__gridHolder{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  gap: 10px;  
}
.ss__timeBadgeTime{
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.ss__timeBadgeTime span{
  color: var(--red);

}
.ss_timeBadge{
    background-color: var(--market_dropItem);
}
.ss__calendarHolder{
  border: 1px solid var(--market_dropItem);
  width: fit-content;
}
.deposit_custom_datepoicker.ss_calendar{
  border-color: transparent;
  height: 34px;
}
.ss_calendar .react-datepicker__month-container{
  border: 1px solid var(--white) !important;
  z-index: 10;
}
.ss_calendar .react-datepicker,.ss_calendar .react-datepicker__header {
  background-color: var(--body_bgcolor);
  border-top: 1px solid var(--white);
}
.ss_calendar .react-datepicker__header {
  border-top: 1px solid var(--white);
}
.ss_calendar .react-datepicker__day-name,.ss_calendar .react-datepicker__current-month,.ss_calendar .react-datepicker__day{
  color: var(--white) !important;
}
.ss_calendar .react-datepicker__day:hover{
  background-color: var(--primary_color);
}
.ss_calendar .react-datepicker__day--range-start,.ss_calendar .react-datepicker__day--range-end{
  background-color: var(--primary_color);
}
.src__singleGrid label,.src__singleGrid p{
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_five);
  white-space: nowrap;
}
.src__singleGrid span{
  color: var(--primary_color);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_five);
  white-space: nowrap;
}
.src__gridHolder{
  background-color: var(--storeReg_card);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px 30px;  
  flex-wrap: wrap;
}
.src__singleGrid{
  min-width: 120px;
}
.src__dropdown button{
  background-color: transparent;
  border: 1px solid var(--primary_color);
  outline: none;
  box-shadow: none !important;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);  

}
.src__dropdown button:focus,.src__dropdown button:active,.src__dropdown button:focus-visible,.src__dropdown button:hover{
  background-color: transparent !important;
  border-color: var(--primary_color) !important;
}
.src__dropdownMenu{
  background-color: var(--homecard_bg);
}
.src__dropdownItem{
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.src__dropdownItem:hover{
  background-color: var(--primary_color);
}
.src__completeBtn.primary_btn{
  padding: 5px 7px;
  min-width: unset;
  font-size: var(--font_twelve);
}
.src__completeBtn.primary_btn:hover{
  background-color: var(--primary_color);
  color: var(--black);
}
.ss__searchHolderer {
  border: 1px solid var(--border_change);
  outline: none;
  background-color: var(--stable_bottomGradient);
  color: var(--white);
  font-size: var(--font_thirteen);
}
.ss__emptyInput{
  border: none;
  outline: none;
  background-color: transparent;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
/* end of store css */

/* product registration css */
.prodReg__productTaber {
  padding: 7px 25px;
  background-color: transparent;
  cursor: pointer;
}
.prodReg__productTaber.active {
  background-color: var(--primary_color);
}
.prodReg__productTaber:hover,.prodReg__productTaber:hover p{
  background-color: var(--primary_color);
  color: var(--black);
}
.prodReg__productTaber p {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.prodReg__productTaber.active p{
  color: var(--black);
}
.prodReg__topTaber {
  width: 20%;
  padding: 7px;
  background-color: transparent;
  cursor: pointer;
}
.prodReg__topTaber.active {
  background-color: var(--primary_color);
}
.prodReg__topTaber:hover,.prodReg__topTaber:hover p{
  background-color: var(--primary_color);
  color: var(--black);
}
.prodReg__topTaber p {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.prodReg__topTaber.active p{
  color: var(--black);
}
.prodReg__fileInputter{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  opacity: 0;
}
.prodReg__uploadedImg{
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  object-fit: contain;
}
.prodReg__label{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
/* end of product registration css */

/* sale status css */
.saleStatus__blackListBox{
  background-color: var(--chat_bg);
  border: 1px solid var(--blackList_border);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.saleStatus__blackListTitle{
  color: var(--blackList_border);
  font-size: var(--font_twenty);
  font-weight: var(--weight_six);
}

.saleStatus_blackListHint,.saleStatus__blackListBox li{
  color: var(--blackList_border);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}

/* end of sale status css */

/* 29-01-24 sakthi */

@media (max-width: 480px) {
  .cmn_tabs .cnt_sec {
    padding: 10px !important;
  }
  /* .cmn_tabs .custom_tabs {
    display: unset !important;
  } */
  .cmn_tabs .custom_tabs .heading {
    margin-bottom: 10px !important;
  }
  /* .tabs_heading_active {
    font-size: 14px !important;
  }
  .tabs_heading_active {
    padding: 7px 18px !important;
  } */
  /* .custom_tabs .tabs_heading {
    padding: 7px 18px !important;
  } */

  .assets .total_bal {
    display: unset !important;
  }
  /* .assets .custom_usd_drop button {
    padding-left: 0px !important;
    font-size: 20px !important;
  } */
  .assets .sub_drop .dropdown {
    text-align: start !important;
  }
  .assets .sub_drop button {
    font-size: 18px !important;
    padding-left: 0px;
  }
  /* .custom_usd_drop .dropdown-item {
    font-size: 18px !important;
  } */

  /* .recent_activity h5 {
    font-size: 26px !important;
  } */
  .assets .recent_activity {
    padding: 15px !important;
  }
  /* .details_list .cnt {
    padding: 10px !important;
  } */
  /* .details_list .cnt {
    overflow-x: auto;
    gap: 30px;
  } */
  /* .details_list .withdraw {
    font-size: 20px !important;
  } */
  /* .details_list .account {
    font-size: 14px !important;
  } */
  /* .details_list .cnt .snd_sec .usdt {
    font-size: 15px !important;
  } */
  .details_list .snd_sec .date_time {
    font-size: var(--font_twelve) !important;
    gap: 5px !important;
  }
  /* .details_list .cnt .fst_sec {
    gap: 15px !important;
  } */

  .deposit .qrcode {
    align-items: center;
  }
  .deposit .usdt_add {
    font-size: 16px !important;
  }
  .deposit .usdt {
    width: 20px;
    height: 20px;
  }
  .deposit .qrcode .address_val span {
    font-size: 14px !important;
  }
  .deposit .qrcode .copy_add {
    font-size: 13px !important;
  }
  .deposit .coin p {
    font-size: 15px !important;
  }
  .deposit .snd_sec .tips {
    font-size: 20px !important;
  }
  .deposit .cnt_details .details {
    font-size: 15px !important;
  }
  .deposit .qrcode .qr {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
  .deposit_credit ,.deposit_credit .refund_request{
    font-size: var(--font_thirteen) !important;
  }
  .custom_deposit_table {
    padding: 5px 15px !important;
  }


  .withdraw_tabs .nav-pills {
    width: 100%;
    flex-wrap: nowrap !important;
    overflow-x: auto;
  }

  .withdraw_tabs .nav-pills .nav-link {
    font-size: 16px !important;
  }
  .on_chain .network_sec .network {
    font-size: 15px !important;
  }
  .on_chain .network_sec .cnt_address {
    font-size: 15px !important;
    text-align: end;
  }

  .withdraw .alert_sec span {
    font-size: 14px !important;
  }
  .withdraw_content .label {
    font-size: 19px !important;
  }
  .withdraw_address input {
    font-size: 12px !important;
  }
  /* .on_chain .address_icons {
    width: 11px;
    height: 11px;
  } */
  .withdraw_content .step_3 .amt {
    font-size: 18px !important;
  }
  .withdraw_content .step_3 .limit {
    text-align: end;
  }
  .step_3 .available {
    font-size: 15px;
  }
  /* .step_3 .ftr_sec {
    font-size: 15px !important;
  } */
  .withdraw_content .fst_sec {
    padding: 30px 13px !important;
  }
  .faq_accr .accordion-button {
    font-size: 15px;
  }
  .faq_accr .accordion-body {
    font-size: 13px;
  }

  .spot .autotrade_pnl {
    flex-direction: column;
    gap: 15px;
  }
  .spot .autotrade_pnl .snd_sec {
    text-align: end;
  }
  .assets .area_chart_sec {
    padding: 13px !important;
  }
  .area_chart canvas {
    height: 170px !important;
  }
  /* .custom_spot_table .header_collec {
    flex-direction: column;
    gap: 15px !important;
  } */
  .assets .donut_chart_sec {
    padding: 15px !important;
  }
  .assets .donut_chart_sec .header h4 {
    font-size: 15px !important;
  }
  .spot .custom_tabs_sec span {
    width: 120px !important;
  }
}
@media (min-width: 481px) and (max-width: 576px) {
  .cmn_tabs .cnt_sec {
    padding: 15px !important;
  }
  /* .cmn_tabs .custom_tabs {
    display: unset !important;
  } */
  .cmn_tabs .custom_tabs .heading {
    margin-bottom: 10px !important;
  }
  /* .tabs_heading_active {
    font-size: 14px !important;
  }
  .tabs_heading_active {
    padding: 7px 18px !important;
  } */
  /* .custom_tabs .tabs_heading {
    padding: 7px 18px !important;
  } */

  /* .recent_activity h5 {
    font-size: 25px !important;
  } */
  .assets .recent_activity {
    font-size: 15px !important;
  }
  /* .details_list .cnt {
    padding: 15px !important;
  } */
  /* .details_list .withdraw {
    font-size: 20px !important;
  } */

  /* .assets .balance {
    font-size: 20px !important;
  } */
  /* .assets .custom_usd_drop button {
    font-size: 21px !important;
  } */
  /* .custom_usd_drop .dropdown-item {
    font-size: 17px !important;
  } */
  /* .recent_activity h5 {
    font-size: 22px !important;
  } */
  .area_chart canvas {
    height: 240px !important;
  }
  .deposit .table_custom_header_add {
    flex-direction: column;
    gap: 20px !important;
  }
  /* .custom_spot_table .header_collec {
    flex-direction: column;
    gap: 15px !important;
  } */
}
@media (min-width: 577px) and (max-width: 768px) {
  .cmn_tabs .cnt_sec {
    padding: 20px !important;
  }
  /* .cmn_tabs .custom_tabs {
    display: unset !important;
  } */
  .cmn_tabs .custom_tabs .heading {
    margin-bottom: 10px !important;
  }
  /* .tabs_heading_active {
    font-size: 14px !important;
  }
  .tabs_heading_active {
    padding: 7px 18px !important;
  } */
  /* .custom_tabs .tabs_heading {
    padding: 7px 18px !important;
  } */
  /* .assets .balance {
    font-size: 20px !important;
  } */
  /* .assets .custom_usd_drop button {
    font-size: 21px !important;
  } */
  /* .custom_usd_drop .dropdown-item {
    font-size: 17px !important;
  } */
  .mt_selects_label{
    min-width: 100px;
  }
  /* .recent_activity h5 {
    font-size: 22px !important;
  } */
  /* .custom_spot_table .header_collec {
    flex-direction: column;
    gap: 15px !important;
  } */
  .custom_spot_table .convert_sec {
    font-size: 14px !important;
  }
  .check_sec .form-group label {
    font-size: 14px !important;
  }
  /* .custom_spot_table .search_sec input {
    width: 175px !important;
  } */
}
@media (min-width: 769px) and (max-width: 991px) {
  .cmn_tabs .cnt_sec {
    padding: 20px !important;
  }
  /* .cmn_tabs .custom_tabs {
    display: unset !important;
  } */
  .cmn_tabs .custom_tabs .heading {
    margin-bottom: 10px !important;
  }
  /* .tabs_heading_active {
    font-size: 14px !important;
  }
  .tabs_heading_active {
    padding: 7px 18px !important;
  } */
  /* .custom_tabs .tabs_heading {
    padding: 7px 18px !important;
  } */
  /* .assets .balance {
    font-size: 20px !important;
  } */
  /* .assets .custom_usd_drop button {
    font-size: 21px !important;
  } */
  /* .custom_usd_drop .dropdown-item {
    font-size: 17px !important;
  } */
  /* .recent_activity h5 {
    font-size: 23px !important;
  } */
}
@media (min-width: 992px) and (max-width: 1199px) {
  /* .assets .balance {
    font-size: 20px !important;
  } */
  .assets .custom_usd_drop button {
    font-size: 21px !important;
  }
  /* .custom_usd_drop .dropdown-item {
    font-size: 17px !important;
  } */
  /* .recent_activity h5 {
    font-size: 25px !important;
  } */
  .spot .autotrade_pnl .trans_his {
    font-size: 13px;
  }
  .spot .autotrade_pnl .analysis {
    font-size: 13px;
  }
  .area_chart canvas {
    height: 304px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  /* .assets .balance {
    font-size: 20px !important;
  } */
  /* .assets .custom_usd_drop button {
    font-size: 21px !important;
  } */
  /* .custom_usd_drop .dropdown-item {
    font-size: 17px !important;
  } */
  /* .recent_activity h5 {
    font-size: 27px !important;
  } */
}

@media (min-width: 1200px) {
  .area_chart canvas {
    height: 304px !important;
  }
}

.cmn_tabs .cnt_sec.container {
  max-width: 90% !important;
}
.cmn_tabs .header.container {
  max-width: 90% !important;
}

.cmn_tabs {
  background-color: var(--body_bgcolor);
}
.cmn_tabs .cnt_sec {
  /* opacity: 0.5;
  background: linear-gradient(109deg, var( --stable_topGradient) 2.91%, var( --stable_bottomGradient) 101.27%);
  box-shadow: 0px 4px 109px 0px rgba(0, 0, 0, 0.25); */

  background-image: linear-gradient(var(--homecard_bg), var(--homecard_bg));
  padding: 50px;
  z-index: 1000;
  position: relative;
}
.cmn_tabs .custom_tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom_tabs .tabs_heading {
  /* color: var(--white); */
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  border-radius: 50px;
  background: var(--market_dropItem);
  padding: 10px 20px;
  text-align: center;
}
.tabs_heading_active {
  /* color: var(--white); */
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  border-radius: 50px;
  background: var(--switcher);
  padding: 10px 20px;
  text-align: center;
}
.tabs_head_cnt span:hover {
  background: var(--switcher);
}

.custom_tabs .heading {
  color: var(--white);
  font-size: var(--font_twentyfive);
  font-weight: var(--weight_seven);
}
.tabs_head_cnt {
  /* display: flex;
  align-items: center;
  gap: 20px;
  overflow: auto;
  padding-bottom: 10px; */
  /* flex-wrap: wrap; */
}
.tabs_head_cnt span {
  cursor: pointer;
}

.assets .balance {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  text-decoration-line: underline;
  white-space: nowrap;
}

.assets .total_bal {
  display: flex;
  align-items: baseline;
  gap: 20px;
}
.assets .custom_usd_drop button {
  background: transparent !important;
  border: none;
  outline: none;
  font-size: var(--font_twentyfive);
  font-weight: var(--weight_seven);
  color: var(--white) !important;
  font-family: var(--inter_font);
}

.custom_usd_drop .dropdown-menu {
  /* background-color: var(--market_dropItem); */
  background-color: var(--homecard_bg);
}
.custom_usd_drop .dropdown-item {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-family: var(--inter_font);
  font-weight: var(--weight_four);
}
.custom_usd_drop .dropdown-item:hover {
  /* background-color: var(--market_dropItem_hover); */
  background-color: var(--primary_color);
}
.assets .sub_drop .dropdown-menu {
  /* background-color: var(--market_dropItem); */
  background-color: var(--homecard_bg);
}
.assets .sub_drop .dropdown-item {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  font-family: var(--inter_font);
}
.assets .sub_drop .dropdown-item:hover {
  /* background-color: var(--market_dropItem_hover); */
  background-color: var(--primary_color);
}
.assets .sub_drop button {
  background: transparent !important;
  border: none;
  outline: none;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-family: var(--inter_font);
  font-weight: 300;
  color: var(--white) !important;
}
.assets .sub_drop .dropdown {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  text-align: end;
}
.assets .recent_activity {
  padding: 20px;
  border-radius: 6px;
  border: 1px solid var(--border_change);
  /* opacity: 0.5; */
  background: linear-gradient(
    109deg,
    var(--stable_topGradient) 2.91%,
    var(--stable_bottomGradient) 101.27%
  );
}

.recent_activity h5 {
  color: var(--white);
  font-size: var(--font_twentyfive) !important;
  font-weight: var(--weight_seven);
}
.recent_activity .details_list .cnt:hover {
  background: var(--lite_gray);
}
.recent_activity .details_list .cnt {
  background: transparent;
  cursor: pointer;
}

.details_list .cnt .fst_sec {
  display: flex;
  align-items: center;
  gap: 20px;
}
.details_list .withdraw {
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_five);
}
.details_list .account {
  color: var(--primary_color);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.details_list .cnt .fst_sec .noti {
  width: 10px;
  height: 10px;
  background-color: var(--primary_color);
  position: absolute;
  border-radius: 50%;
  right: 0px;
}
.details_list .cnt .snd_sec .usdt {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.details_list .snd_sec .date_time {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  display: flex;
  gap: 10px;
}
.details_list .snd_sec .date_time span {
  display: flex;
  align-items: center;
}
.details_list .cnt .fst_sec .user_logo {
  width: 35px ;
  height: 35px ;
  min-width: 35px;
  min-height: 35px ;
  max-width: 35px;
  max-height: 35px ;
}

/* deposit */
.deposit .fst_sec {
  border-radius: 6px;
  border: 1px solid var(--border_change);
  /* opacity: 0.5; */
  background: linear-gradient(
    109deg,
    var(--stable_topGradient) 2.91%,
    var(--stable_bottomGradient) 101.27%
  );
  padding: 20px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 99%;
}
.deposit .step {
  border-radius: 50px;
  background: var(--lite_gray1);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
  color: var(--white);
  border-radius: 50px;
  padding: 10px 20px;
}
.deposit .coin p {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  margin-bottom: 10px !important;
}
.deposit .coin .dropdown {
  /* border-radius: 6px;
  border: 0.5px solid var( --primary_color);
  background: var( --stable_bottomGradient) !important; */
}
.deposit .coin .dropdown button {
  width: 100%;
  border-radius: 6px;
  border: 0.5px solid var(--primary_color);
  background: var(--stable_bottomGradient) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
}
.deposit .coin .dropdown .dropdown-menu.show {
  width: 100%;
}
.deposit .coin .dropdown .dropdown-item {
  color: var(--white);
  font-size: 16px;
}
.deposit .coin .dropdown .dropdown-item:hover {
  /* background-color: var(--market_dropItem_hover); */
  background-color: var(--primary_color);
}
.deposit .coin .dropdown .dropdown-menu {
  /* background-color: var(--market_dropItem); */
  background-color: var(--homecard_bg);
}
.deposit .dpt_add {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deposit .manage {
  font-size: 16px !important;
}
.deposit .qrcode {
  border-radius: 6px;
  background: var(--lite_gray2);
  padding: 10px;
  display: flex;
  gap: 30px;
  /* align-items: center; */
  /* justify-content: space-between; */
}
.deposit .qrcode .address_val {
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 6px;
  border: 0.5px solid var(--border_change);
  background: var(--stable_bottomGradient);
  padding: 7px 10px;
}
.deposit .copy_address span {
  color: var(--primary_color);
  font-size: 16px;
  font-weight: 400;
}
.deposit .qrcode .w-100 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.deposit .qrcode .address_val span {
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.deposit .qrcode .copy_add {
  color: var(--primary_color);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  cursor: pointer;
}

.deposit .snd_sec {
  background-color: var(--walletcard_bg);
  /* padding: 20px; */
}
.deposit .snd_sec .tips {
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_six);
}
.deposit .snd_sec .dec {
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.deposit .cnt_details {
  border-radius: 6px;
  background: var(--lite_black) !important;
  margin: 2px;
  padding: 20px;
  position: relative;
  bottom: 2px;
}
.deposit .cnt_details .details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}

/* dropdown */
.details .transfer button {
  border-radius: 6px;
  border: none !important;
  background: transparent !important;
  padding-right: 0px !important;
  color: var(--white);
  font-weight: var(--weight_five);
  font-size: var(--font_fifteen);
  font-family: var(--inter_font);
}

.details .transfer .dropdown-item {
  color: var(--white);
  font-size: 16px;
}
.details .transfer .dropdown-item:hover {
  /* background-color: var(--market_dropItem_hover); */
  background-color: var(--primary_color);
}
.details .transfer .dropdown-menu {
  /* background-color: var(--market_dropItem); */
  background-color: var(--homecard_bg);
}
/* dropdown end */
.cnt_details .warning_msg {
  color: var(--warning_msg);
  font-size: 13px;
  font-weight: 400;
}
.cnt_details .note {
  color: var(--white);
  font-size: 13px;
  font-weight: 400;
}

.deposit .manage_address {
  text-decoration: none;
}
.deposit .manage_address:hover p {
  /* text-decoration: underline; */
  color: var(--primary_color) !important;
}

.deposit_credit {
  color: var(--white);
  /* text-align: end; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: var(--font_sixteen);
  font-weight: var(--weight_five);
}
.deposit_credit .refund_request {
  color: var(--primary_color) !important;
  text-decoration: none;
  font-size: var(--font_sixteen);
  font-weight: var(--weight_five);
}
.custom_deposit_table {
  border-radius: 0px 6px 6px 6px;
  border: 1px solid var(--border_change);
  /* opacity: 0.5; */
  /* background: var(--stable_gradient);; */
  background: var(--stable_gradient);
  padding: 5px 30px;
  position: relative;
}
.all_coins button {
  border-radius: 6px;
  border: 0.5px solid var(--primary_color) !important;
  background: var(--stable_bottomGradient) !important;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white) !important;
}
.all_coins button:hover {
  color: var(--white) !important;
}
.all_coins .dropdown-item {
  /* color: var(--white); */
  color: var(--white);
  font-size: 16px;
}
.all_coins .dropdown-item:hover {
  /* background-color: var(--market_dropItem_hover); */
  background-color: var(--primary_color);
}
.all_coins .dropdown-menu {
  /* background-color: var(--market_dropItem); */
  background-color: var(--homecard_bg);
  width: 200px;
}
.custom_deposit_table th.sortable {
  color: var(--white);
  font-weight: 500;
}
.custom_deposit_table .table {
  --bs-table-bg: transparent !important;
  --bs-table-border-color: transparent !important;
  color: var(--white);
  --bs-table-color: var(--white) !important;
}

.custom_deposit_table .table-striped > tbody > tr:nth-of-type(odd) > * {
  color: var(--white);
  font-weight: 300;
  background-color: transparent;
  /* text-align: center; */
  padding-top: 20px;
}

.custom_deposit_table .table-body {
  overflow-x: auto;
}

/* withdraw */
.withdraw_content .fst_sec {
  border-radius: 6px;
  border: 1px solid var(--border_change);
  background: var(--stable_gradient);
  padding: 40px 20px;
}

.withdraw_content .step {
  border-radius: 50px;
  background: var(--lite_gray1);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  color: var(--white);
  border-radius: 50px;
  padding: 10px 20px;
}

.withdraw_content .cmn_icon {
  width: 20px;
  height: 20px;
}

.withdraw_content .coin_drop .dropdown-menu {
  width: 100%;
}

.withdraw_content .label {
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
}

.withdraw_tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent !important;
  border-bottom: 3px solid var(--white);
  border-radius: 0px !important;
}
.withdraw_tabs .nav-pills .nav-link {
  color: var(--white);
  padding-left: 2px;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_five);
}
.withdraw_tabs .nav-pills .nav-link {
  border-bottom: 3px solid transparent;
  border-radius: 0px !important;
}
.withdraw_tabs .nav-pills .nav-link:hover {
  border-bottom: 3px solid var(--white);
  border-radius: 0px !important;
}
.withdraw_tabs .nav-pills {
  /* background: url('../src/assets/images/cmn_tabs/tabs_bdr.png');
  height: 1px;
  width: 100%; */

  border-bottom: 1px solid;
  border-image: url("../images/cmn_tabs/tabs_bdr.png");
  border-image-slice: 1;
}
/* .withdraw_tabs .on_chain .network_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */
.on_chain .network_sec .network {
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_five);
}
.on_chain .network_sec .cnt_address {
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_four);
}
.on_chain .network_sec .address_val {
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_five);
}
.withdraw_select_cointType {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  position: absolute;
  top: 22%;
  left: 4%;
}
.on_chain .alert_sec {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--lite_gray3);
  padding: 15px 10px;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.on_chain .withdraw_address .label {
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
}

.withdraw_address input {
  border-radius: 6px;
  border: 0.5px solid var(--primary_color);
  background: var(--stable_bottomGradient);
  padding: 5px 10px;
  width: 100%;
  color: var(--white);
  font-weight: 300;
  font-size: 15px;
}
.withdraw_avail_value {
  font-size: var(--font_thirteen);
  font-weight: var(--weight_six);
}
.withdraw_content .step_3 {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.withdraw_content .step_3 .amt {
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
  color: var(--white);
}
.withdraw_content .step_3 .limit {
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.withdraw_content .step_3 .withdrawal_amt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.withdraw_content .step_3 .min_amt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 6px;
  border: 0.5px solid var(--border_change);
  background: var(--stable_bottomGradient);
}
.min_amt .green {
  color: var(--primary_color);
}
.step_3 .transfer {
  color: var(--primary_color);
}
.step_3 .available {
  display: flex;
  align-items: center;
  justify-content: end;
  /* gap: 5px; */
}
.step_3 .arrow_icon {
  color: var(--primary_color);
}
.step_3 .ftr_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.primary_btn:hover {
  border: 1px solid var(--primary_color);
}
.withdraw .withdraw_btn {
  width: 200px;
}
.withdraw .snd_sec {
  color: var(--white);
}
.withdraw .snd_sec .tips_sec {
  border-radius: 6px;
  background: var(--walletcard_bg);
}
.withdraw .tips_sec .tips {
  font-size: var(--font_twenty);
  color: var(--white);
  font-weight: var(--weight_six);
}
.withdraw .tips_sec .dec {
  font-size: var(--font_fifteen);
  color: var(--white);
  font-weight: var(--weight_five);
}
.withdraw__historyTitle{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
.withdraw__historyCoinName{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.withdraw__title{
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_seven);
}
.withdraw__editAction{
  border: 1px solid var(--primary_color);
  outline: none;
  color: var(--black);
  font-size: var(--font_fifteen);
  background-color: var(--primary_color);
  font-weight: var(--weight_five);
}
.withdraw__editAction:hover{
  background-color: transparent;
  color: var(--primary_color);

}
.withdraw__deleteAction{
  border: 1px solid var(--red);
  outline: none;
  color: var(--black);
  font-size: var(--font_fifteen);
  background-color: var(--red);
  font-weight: var(--weight_five);
}
.withdraw__deleteAction:hover{
  background-color: transparent;
  color: var(--red);
}
.withdraw__historyScroller{
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.withdraw__historyBadge{
  background-color: transparent;
  min-width: 100px;
  max-width: 100px;
}
.withdraw__historySuccess{
  border: 1px solid var(--profile_success);
}
.withdraw__historyProgress{
  border: 1px solid var(--profile_orange);
}
.withdraw__historyFailed{
  border:1px solid var(--red);
}
.withdraw__singleFlex{
  border-bottom: 1px solid var(--border_change);
}
.withdraw__historyNoData{
  width: 200px;
  height: 200px;
}
.faq_accr{
  background-color: var(--walletcard_bg);
}
.faq_accr .head_sec .header_txt {
  font-size: var(--font_twenty);
  color: var(--white);
  font-weight: var(--weight_six);
}
.faq_accr .head_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq_accr .head_sec .arrow_icon {
  color: #7b7b7b;
  font-size: 24px;
}
.faq_accr .accordion {
  --bs-accordion-color: var(--white);
  --bs-accordion-bg: var(--lite_black);
  border-radius: 6px;
  color: var(--white);
  box-shadow: none !important;
  border: none !important;
  --bs-accordion-border-color: transparent !important;
}
.faq_accr .accordion-button {
  color: var(--white);
  background-color:transparent;
  box-shadow: none !important;
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}

.faq_accr .accordion-item:nth-child(odd) {
  background-color:var(--purchasepop_inpBorder);
}
.faq_accr .accordion-item:nth-child(even) {
  background-color:transparent !important;
}

.faq_accr .accordion-button:not(.collapsed)::after {
  transform: rotate(0deg) !important;
  filter: brightness(0.7) invert(0.6);
}
.faq_accr .accordion-button.collapsed::after {
  transform: rotate(-90deg) !important;
  filter: brightness(0.7) invert(0.6);
}
.withdraw_accord_bodcnt {
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.faq_accr .more_faq {
  text-decoration: none;
  color: var(--white);
  position: relative;
  right: 20px;
}
.faq_accr .more_faq:hover {
  color: var(--primary_color) !important;
}
.faq_accr .accordion-body {
  border-top: 1px solid var(--accor_bg);
}
.assets .donut_chart_sec {
  border-radius: 6px;
  border: 1px solid var(--border_change);
  background: var(--stable_gradient);
  padding: 30px;
  min-height: 380px;
  max-height: 380px;
}

.assets .donut_chart_sec .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--white);
}
.assets .donut_chart_sec .header h4 {
  font-size: var(--font_twenty);
  color: var(--white);
  font-weight: var(--weight_seven);
}
.donut_chart_sec canvas {
  width: 300px !important;
  height: 300px !important;
}
.doughnut_cus {
  display: flex;
  align-items: center;
  justify-content: center;
}
.assets .area_chart_sec {
  border-radius: 6px;
  border: 1px solid var(--border_change);
  background: var(--stable_gradient);
  padding: 30px;
  min-height: 380px;
  max-height: 380px;
}
.area_chart canvas {
  width: 100% !important;
  height: 300px;
  color: var(--white);
}

.area_chart_sec .header {
  display: flex;
  align-items: center;
  gap: 10px;
}
.area_chart_sec .date {
  color: var(--white);
  font-size: 13px;
  font-weight: 500;
  padding: 5px 15px;
  border-radius: 50px;
}
.area_chart_sec .active_date {
  /* background: var(--market_paymentBtn); */
  background: var(--little_lite_gray);
}
.area_chart_sec .inactive_date {
  background: var(--lite_gray);
}

/* custom toggle start*/

.custom_toggle label {
  font-size: 13px;
  color: var(--white);
  font-weight: 500;
  cursor: pointer;
  width: 140px !important;
  height: 30px !important;
  position: relative;
  top: 2px;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 140px;
  height: 30px;
  background: var(--lite_gray);
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
  /* left: -100px; */
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: var(--font_twelve);
  font-weight: 500;
  top: 7px;
  right: 20px;
  font-weight: var(--weight_five);
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 70px;
  height: 29px;
  /* background: var(--market_paymentBtn); */
  background: var(--little_lite_gray);
  border-radius: 26px;
  position: absolute;
  /* left: 2px; */
  /* top: 2px; */
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px var(--lite_black1);
  padding: 6px 0px 3px 0px;
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}

.btn-color-mode-switch > .alert {
  display: none;
  background: var(--alert);
  border: none;
  color: var(--white);
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner {
  background: var(--lite_gray);
  color: var(--white);
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 68px;
  /* background: var(--market_paymentBtn); */
  background: var(--little_lite_gray);
}

.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 13px;
}

.btn-color-mode-switch input[type="checkbox"]:checked ~ .alert {
  display: block;
}

/* custom toggle end */

/* Spot */
.spot .autotrade_pnl {
  display: flex;
  justify-content: space-between;
  color: var(--white);
}
.spot .autotrade_pnl .pnl {
  display: flex;
  align-items: center;
  gap: 20px;
}
.spot .autotrade_pnl .auto {
  color: var(--primary_color);
  font-size: var(--font_fifteen);
}
.spot_usdt_value {
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_six);
}
.spot .autotrade_pnl .analysis {
  border-radius: 50px;
  background: var(--market_paymentBtn);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  padding: 5px 15px;
  color: var(--white);
  text-decoration: none;
}
.spot .autotrade_pnl .analysis:hover {
  color: var(--primary_color);
}
.spot .autotrade_pnl .val {
  font-size: var(--font_eighteen);
  font-weight: var(--weight_four);
}
.spot .autotrade_pnl .trans_his {
  background-color: var(--lite_gray2);
  border-radius: 50px;
  padding: 11px 15px;
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}

.custom_spot_table .search_sec input {
  border-radius: 6px;
  border: 0.5px solid var(--border_change);
  background: var(--stable_bottomGradient);
  padding: 8px 8px 8px 40px;
  /* width: 230px; */
  width: 100%;
  max-width: 100%;
  color: var(--white);
  outline: none !important;
  box-shadow: none !important;
}
.custom_spot_table .search_sec input::placeholder {
  color: var(--white);
}
.custom_spot_table .search_sec .search_icon {
  color: var(--white);
  position: absolute;
  top: 11px;
  left: 8px;
  font-size: var(--font_twenty);
  font-weight: var(--weight_six);
}
/* .custom_spot_table .header_collec {
  display: flex;
  gap: 25px;
  align-items: center;
  color: var(--white);
  font-weight: 300;
} */

/* custom checkbox start */

.check_sec .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.check_sec .form-group label {
  position: relative;
  cursor: pointer;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.convert_sec {
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}

.check_sec .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid var(--white);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 3px;
  position: relative;
  top: -1px;
}

.check_sec .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: solid var(--white);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* custom checkbox end */

/* .action_datas {
  display: flex;
  gap: 6px;
} */
.action_datas .depo_with {
  color: var(--lite_blue);
  text-decoration: none;
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.action_datas .history {
  color: var(--primary_color);
  text-decoration: none;
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}

.spot .custom_deposit_table .as-react-table .col-md-12 {
  height: 400px;
}
.custom_deposit_table .as-react-table thead {
  position: sticky;
  top: -2px;
  /* background-color: var(--market_tabBg); */
  background-color: var(--table_heading_bg);
}
.spot .custom_tabs_sec .active {
  border: 1px solid var(--primary_color);
  background: var(--primary_color);
  color: var(--black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.spot .custom_tabs_sec .inactive {
  border: 1px solid transparent;
  background: transparent;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.spot .custom_tabs_sec span {
  border-radius: 6px 6px 0px 0px;
  padding: 5px 15px;
  width: 25%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.spot .custom_tabs_sec span:hover {
  background: var(--primary_color);
}

/* .cmn_tabs .cnt_sec {
  position: relative;
} */
.cmn_tabs {
  position: relative;
}
.cmn_tabs .cmn_bg_shadow {
  position: absolute;
  top: 10%;
  left: 0px;
  opacity: 0.7;
}

/* custom datepicker start */
.deposit .react-datepicker__month-container {
  /* background-color: var(--market_tabBg); */
  background-image: linear-gradient(var(--homecard_bg), var(--homecard_bg));
  color: var(--white) !important;
}

.deposit .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--white) !important;
}
.deposit .react-datepicker__day:hover,
.deposit .react-datepicker__time-name:hover {
  color: var(--black) !important;
}
.deposit .react-datepicker__day--keyboard-selected,
.deposit .react-datepicker__month-text--keyboard-selected,
.deposit .react-datepicker__quarter-text--keyboard-selected,
.deposit .react-datepicker__year-text--keyboard-selected {
  background-color: var(--primary_color) !important;
}
.deposit .react-datepicker__header {
  /* background-color: var(--market_tabBg); */
  background-image: linear-gradient(var(--homecard_bg), var(--homecard_bg));
}

.deposit .react-datepicker__day--in-range:hover {
  background-color: var(--primary_color) !important;
}
.deposit .react-datepicker__day--in-range {
  background-color: var(--primary_color) !important;
}

.deposit .react-datepicker__current-month {
  color: var(--white);
}

.deposit .react-datepicker {
  /* border-bottom: 1px solid; */
  /* border-image: linear-gradient(var(--homecard_bg), var(--homecard_bg)) !important; */
  /* border-image-slice: 1 !important; */
  /* border-color: var(--white) !important; */
}

.deposit .react-datepicker__day:hover {
  background-color: var(--primary_color) !important ;
}

.deposit .coin .dropdown .dropdown-item:hover {
  /* background-color: var(--market_dropItem_hover); */
  background-color: var(--primary_color);
}
.deposit .coin .dropdown .dropdown-menu {
  /* background-color: var(--market_dropItem); */
  background-color: var(--homecard_bg);
}
[data-theme="light"] .qrcode .qr {
  filter: brightness(0.5);
}

[data-theme="light"] .snd_sec .tips .tips {
  filter: brightness(0.2);
}
.deposit_table_action a {
  text-decoration: none;
  color: var(--white);
}

/* modal css */
.purchase_modal .modal-content {
  position: relative;
  background-color: var(--market_tablecanva);
}
.cmn_modal_closer {
  border: none;
  outline: none;
  padding: 5px 12px;
  background-color: var(--cmn_modal_closer);
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_seven);
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 5;
}
.puchasepop_secondary_ttl {
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.purchasepop_gray_label {
  border: none;
  outline: none;
  background-color: var(--market_dropItem);
  color: var(--white);
  height: 50px;
  width: 100%;
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.purchasepop_value_shower {
  background-color: transparent;
}
.purchasepop_value_shower input {
  background-color: transparent;
  border: none;
  flex: 1;
  outline: none;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.purchasepop_valueinp {
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  cursor: pointer;
}
.purchasepop_valueinp span{
  font-size: var(--font_twelve);
}
.purchasepop_valueinpBox{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  border: none;
  outline: none;
  background-color: transparent;
  max-width: 90px;
}
.purchasepop_borderBox {
  border: 1px solid var(--purchasepop_inpBorder);
}
.purchasepop_incdec_btn {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: var(--font_sixteen);
  color: var(--white);
}
.puchasepop_checker .form-check-input.is-valid,
.puchasepop_checker .was-validated .form-check-input:valid {
  border-color: var(--purchasepop_checkborder) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.puchasepop_checker .form-check-input.is-valid:checked,
.puchasepop_checker .was-validated .form-check-input:valid:checked {
  border-color: var(--purchasepop_checkborder) !important;
  background-color: var(--primary_color) !important;
  box-shadow: none !important;
}
.purchasepop_lowerfont {
  margin: 0;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.registerpop_title {
  margin: 0;
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
.registerpop_bottomImg_holder {
  overflow: hidden;
  width: 100%;
  height: 400px;
  border: 1px solid var(--primary_color);
  border-radius: 40px;
}
.registerpop_bottomImg {
  min-width: 100%;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}
.market_coinname_select .market_coinname_img.walBal_img {
  height: 15px;
  width: 15px;
  position: absolute;
  top: 30%;
  left: 5%;
}
.walBal_coinImg {
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  object-fit: contain;
}
.walBal_tableCont {
  border: 1px solid var(--border_change);
}
.walBal_tableCont_single {
  margin-bottom: 15px;
}
.walBal_tableCont_single:last-child {
  margin-bottom: 0px;
}

/* security modal css */
.security_modal_titles{
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.sm_gray_text.pm_primary_text{
  color: var(--profile_garytext);;
}
.sm_eyeImg{
  cursor: pointer;
}
.sm_single_stpper{
  cursor: not-allowed;
}
.sm_step_round{
  height: 20px;
  width: 20px;
  background-color: var(--stpper_roundbg);
}
.sm_step_round p{
  margin: 0;
  color: var(--stepper_roundtext);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.sm_stpper_rightHint{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.sm_stpper_rightHint.active{
  color: var(--primary_color);
}
.sm_stpper_rightHint.deactive{
  color: var(--red);
}
.sm_step_round.deactive{
  background-color: var(--red);
}
.sm_step_round.active{
  background-color: var(--primary_color);
}
.sm_step_round.active p{
  color: #fff;
}
.sm_step_round.deactive p{
  color: #fff;
}
.sm_authcompleted_hint{
  margin: 0;
  font-size: var(--font_twenty);
  color: var(--primary_color);
  font-weight: var(--weight_six);
}
.sm_devicemanger_mobView{
  background-color: var(--security_card);
}
.sm_devicemanage_title{
  margin: 0;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.sm_devicemanage_head{
  border-top: 1px solid var(--white);
  border-bottom: 1px solid var(--white);
}
.sm_devicemanager_holder{
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
.sm_tabtext{
  margin: 0;
  color: var(--white);
  padding: 0px 20px;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  cursor: pointer;

}
.sm_acntact_single_tab .sm_tabtext.active{
  color: var(--primary_color);
}
.sm_acntact_single_tab:hover .sm_tabtext{
  color: var(--primary_color);
}
.sm_login_titller{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.sm_loginHistory_label{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.sm_loginHistory_holder{
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* end of security modal css */
.offertags__title{
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.offerModal__offer_name{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_six);
}
.offerModal__box_con{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.offerModal__card{
  background-color: var(--profile_faqbox);
  border: 1px solid var(--placeholder);
}
.vendorTerms__small{
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_four);
}
.vendorTerms__cont_title{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.vendorTerms__cont_hint{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.reqTransactModal__title{
  color: var(--primary_color);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_seven);
}
.reqTransacModal__desc{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.reqTransacModal__desc span{
  font-weight: var(--weight_six);
}
.reqTransactModal__ques{
  color: var(--white);
}
.reqTransacModal_dualBtns button{
  min-width: 130px;
}
/* end modal css */

/* cmntabs css */
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  top: 20% !important;
}
.apexcharts-legend-series {
  margin-top: 15px !important;
}
.apexcharts-legend-marker {
  margin-right: 10px !important;
}
.cmntabs_linechart_holder .apexcharts-toolbar {
  display: none;
}
.spot_table_singlerow{
  background-color: var(--purchasepop_inpBorder);
}
.deposit_custom_datepoicker{
  border: 1px solid var(--border_change);
  height: 40px;
}
.deposit_custom_datepoicker input{
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-family: var(--inter_font);
  font-weight: var(--weight_five);
  width: 100%;
  cursor: pointer;
}
.deposit_custom_datepoicker .react-datepicker-wrapper {
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
  cursor: pointer;
}
.deposit_calImg{
  position: absolute;
  top: 25%;
  right: 5%;
}
.deposit_calImgInvert{
  filter: invert(1);
  position: absolute;
  top: 25%;
  right: 5%;
}
.deposit_calImg_light{
  filter: invert(1);
  position: absolute;
  top: 25%;
  right: 5%;
}
.asset_table_rightttl{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.asset_single_row{
  background-color: var(--sidebar_bodyBg);
}
.withdraw_wtaddrss_holder{
  border: 1px solid var(--border_change);
  background-color: transparent;
  height: 40px;
}
.withdraw_wtaddrss_holder input{
  border: none;
  outline: none;
  box-shadow: none;
  flex: 1;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.withdraw_wtaddrss_holder img{
  cursor: pointer;
}
.asset_custom_toggler{
  background-color: var(--switcher);
}
.asset_ct_button{
  border: none;
  outline: none;
  color: var(--white);
  background-color: transparent;
  padding: 4px 10px;
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.asset_ct_button.active{
  background-color: var(--lite_gray);
}
/* end of cmntabs css */
/* sidebar css */
.cmn_sidebar{
  max-height: 80vh;
}
.cmn_sidebar_offcanva{
  max-height: 100vh;
}
.cmn_sidebar_profileImg{
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  max-height: 25px;
  max-width: 25px;
}
.cmn_sidebar_head{
  background-color: var(--sidebar_headerBg);
}
.cmn_sidebar_head p{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.cmn_sidebar_body :is(ul, li, a){
  list-style-type: none;
  padding-left: 0;
  color: var(--white);
  text-decoration: none;
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  width: 100%;
}
.cmn_sidebar_body li{
  padding-top: 5px;
  padding-bottom: 5px;
}
.cmn_sidebar_body li:hover,.cmn_sidebar_body li:hover a{
  background-color: var(--primary_color);
  color: var(--black);
  cursor: pointer;
}
.cmn_sidebar_body li.active{
  background-color: var(--primary_color);
  color: var(--black);
}
.cmn_sidebar_body{
  background-color: var(--sidebar_bodyBg);
}
.cmn_sidebar_footer{
  background-image: var(--sidebar_footGrad);
}
.cmn_sidebar_footer p{
  margin: 0;
  font-size: var(--font_twelve);
  color: var(--white);
  font-weight: var(--weight_four);
}
.cmn_sidebar_footer h6{
  margin: 0;
  font-size: var(--font_fifteen);
  color: var(--white);
  font-weight: var(--weight_eight);
}
.sidebar_canva_burg{
  border: none;
  outline: none;
  background-color: var(--primary_color);
  color: var(--black);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_six);
  width: fit-content;
  padding: 3px 5px;
}
.sidebar_canva_closer{
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
  width: fit-content;
}
.sidebar_canva_shower{
  position: fixed;
  top: 100px;
}
/* end of sidebar css */

/* cmnTable css */
.spot_table_img{
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  object-fit: contain;
}
.spot_img_hinter{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_four);
}
.spot_tabler_title{
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
/* end of cmnTable css */
/* notices css */
.prefer_fishblue{
  position: absolute;
  right: -20%;
  height: 400px;
  width: 400px;
  top: 30%;
}
.notices_holder{
  background-image: var(--stable_gradient);
}
.notices_hader_title{
  margin: 0;
  color: var(--white);
  font-size: var(--font_twenty);
  font-weight: var(--weight_seven);
}
.notices_search_glass{
  height: 20px;
  width: 20px;
}
.notices_searcher input{
  background-color: transparent;
  border: none;
  outline: none;
  max-width: 100%;
  color: var(--white);
  columns: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.notices_searcher{
  border: 1px solid var(--border_change);
}
.notices_table_ttile{
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.notices_title_hints{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.pagnation_holder .pagination li,.pagnation_holder .pagination li a{
  background-color: transparent;
  border-radius: 50px;
  border: none;
  outline: none;
  color: var(--white);
  box-shadow: none;
  padding: 3px 7px;
  font-size: var(--font_thirteen);
  font-family: var(--inter_font);
  font-weight: var(--weight_five);
}
.pagnation_holder .pagination .page-item.active{
  background-color: var(--border_change) !important;
}
.pagnation_holder .pagination .page-item.disabled{
  opacity: 0.7;
  cursor: not-allowed;
}
.primary_triple_grad_rel{
  background-image: linear-gradient(to right, #222222, var(--primary_color), #222222);
  height: 2px;
  width: 80%;
  position: relative;
  left: 10%;
}
.primary_triple_grad_rel_light {
  background-image: linear-gradient(to right, #ebebeb, var(--primary_color), #eeeeee);
  height: 2px;
  width: 80%;
  position: relative;
  left: 10%;
}
.ni_hintdate_text{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_seven);
}
.ni_date_span{
  margin: 0;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.ni_common_sub{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.ni_demo_img{
  min-width: 100%;
  object-fit: cover;
  min-height: 250px;
  max-height: 400px;
}
/* end of notices css */
/* profile css */
.profile_change_btn{
  border: 1px solid var(--primary_color);
  outline: none;
  background-color: transparent;
  color: var(--white);
  border-radius: 30px;
  padding: 7px 15px;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.prfl_profile_img_holder{
  width: fit-content;
}
.prfl_profile_img_holder img{
  height: 35px;
  width: 35px;
  min-height: 35px;
  min-width: 35px;
  max-height: 35px;
  max-width: 35px;
}
.prfl_online_indicator{
  height: 10px;
  width: 10px;
  background-color: var(--primary_color);
  border: 1px solid var(--primary_color);
  position: absolute;
  right: 0;
  top: 0;
}
.prfl_username{
  margin: 0;
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_four);
}
.prfl_username_holder img{
  height: 18px;
  width: 18px;
  object-fit: contain;
}
.prfl_accord_holder{
  border: 1px solid var(--profile_accord_border);
}
.prfl_accord_holder button.accordion-button,.prfl_accord_holder .accordion-header,.prfl_accord_holder .accordion-button:not(.collapsed),.prfl_accord_holder .accordion-item{
  background-color: transparent !important;
  color: var(--white);
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.prfl_accord_holder button.accordion-button::after{
  filter: invert(0.5);
  background-size: 15px 15px;
  background-position: center;
}
.prfl_accord_holder button.accordion-button,.prfl_accord_holder .accordion-header{
  font-size: var(--font_sixteen) !important;
  font-weight: var(--weight_seven);
  padding: 0px !important;
}
.prfl_accord_holder .accordion-body{
  padding: 0px !important;
}
.prfl_acrd_value{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_six);
  overflow-wrap: break-word;
  word-break: break-word;
}
.prfl_gray_ttile{
  margin: 0;
  color: var(--profile_garytext);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.prfl_gray_ttile.prfl_red_ttl{
  margin: 0;
  color: var(--red);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.prfl_gray_ttile.prfl_green_ttl{
  margin: 0;
  color: var(--profile_success);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_six);
}
.prfl_box_value{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.prfl_gray_box{
  min-height: 120px;
}
.prfl_addrs_text{
  margin: 0;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
.prfl_single_id{
  cursor: pointer;
}
.prfl_single_id.active{
  background-color: var(--primary_color);
}
.prfl_single_id.active .prfl_passport_img.prfl_id_image{
  filter: invert(0);
}
.prfl_single_id.active .prfl_id_image{
  filter: invert(1);
}
.prfl__proofInvert{
  filter: invert(0) !important;
}
.prfl__proofInvertSec{
  filter: invert(1) !important;
}
.prfl_id_image{
  height: 25px;
  width: 25px;
}
.prfl_single_id:hover{
  background-color: var(--border_change);
}
.prfl_single_id:hover .prfl_id_image{
  filter: invert(1);
}
.prfl_single_id:hover .prfl_passport_img{
  filter: invert(0);
}
.prfl_passport_img{
  filter: invert(1);
}
.prfl_tiny_inputs,.prfl__imgUploader{
  border: 1px solid var(--border_change);
  color: var(--white);
  background-color: transparent;
  outline: none;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  max-width: 100%;
  min-width: 100%;
}

.profile_custom_datepicker{
  border-color: var(--border_change) !important;
}
.profile_custom_datepicker .react-datepicker__header,.profile_custom_datepicker .react-datepicker ,.profile_custom_datepicker  .react-datepicker__current-month,.profile_custom_datepicker  .react-datepicker-time__header,.profile_custom_datepicker  .react-datepicker-year-header,.profile_custom_datepicker .react-datepicker__day-names,.profile_custom_datepicker .react-datepicker__day-name,.profile_custom_datepicker .react-datepicker__day,.profile_custom_datepicker .react-datepicker__time-name{
  background-color: var(--body_bgcolor);
  color: var(--white) !important;
  /* z-index: 100; */
}
.profile_custom_datepicker .react-datepicker__day:hover{
  background-color: var(--border_change);
}
.profile_custom_datepicker input{
  color: var(--white);
  font-family: var(--inter_font);
  font-weight: var(--weight_five);
}
.prfl_primary_sec{
  background-color: var(--primary_color);
  width: fit-content;
  padding: 7px 30px;
  cursor: pointer;
}
.prfl_primary_sec p{
  margin: 0;
  color: var(--black);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.prfl_pic_hinter{
  margin: 0;
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
}
.prfl_profilepic_verifier :is(ul, li){
  list-style-type: disc;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}

.prfl_image_uploader{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  opacity: 0;
  cursor: pointer !important;
}
.prfl_faqs_holder{
  background-color: var(--profile_faqbox);
}
.prlf_single_faq:last-child{
  margin-bottom: 0;
}
.prfl_sample_idImg{
  min-width: 100%;
}
.prfl_status_shower{
  margin: 0;
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_seven);
}
.prfl_lable_badge{
  border: none;
  color: white;
  padding: 9px;
  background-color: var(--switcher);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.prfl_orange_btn{
  border: 1px solid var(--profile_orange);
  outline: none;
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  background-color: var(--profile_orange);
  color: var(--black);
  padding: 7px 15px;
  min-width: 100px;
  width: fit-content;
  border-radius: 5px;
}
.prfl_orange_btn:hover{
  background-color: transparent;
  color: var(--profile_orange);
}
.prfl_glass_searcher{
  border: 1px solid var(--border_change);
}
.prfl_search_glass{
  height: 18px;
  width: 18px;
}
.prfl__proofSelector{
  height: 150px;
  width: 200px;
  min-height: 150px;
  min-width: 200px;
  max-height: 150px;
  max-width: 200px;
  cursor: pointer;
}
.prfl__fileInput{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  opacity: 0;
  cursor: pointer !important;
}
/* end of profile css */
/* recommend css */
.rec_blue_link.notices_title_hints{
  color: var(--recomend_link);
}
.rec_table_ttl{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.rec_table_ttl_light{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four );
  word-break: break-word;
  overflow-wrap: break-word;
}
.rec_table_ttl span{
  font-weight: var(--weight_four);
}
.rec_primary_btn{
  border: 1px solid var(--primary_color);
  outline: none;
  padding: 5px 8px;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  background-color: var(--primary_color);
}
.rec_red_btn{
  border: 1px solid var(--market_sellTab);
  outline: none;
  padding: 5px 8px;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  background-color: var(--market_sellTab);
}
.rec_single_card{
  background-color: var(--walletcard_bg);
}

/* end of recommend css */
/* payment css */

.pm_payment_checker .form-check-input{
  background-color: transparent !important;
  border-color: var(--primary_color);
  box-shadow: none;
}
.pm_payment_checker .form-check-input:checked{
  background-image: url('../images/filler.svg') !important; 
  background-size: 10px;
  background-position: center;
}
.pm_payment_checker.pm_checker_align .form-check-input:checked{
  background-image: url('../images/filler.svg') !important; 
  background-size: 9px;
  background-position: center;
}
/* .pm_glass_searcher{
  border: 1px solid var(--border_change);
} */
/* .pm_glass_searcher input{
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  width: 100%;
  max-width: 100%;
} */
.pm_primary_text{
  margin: 0;
  color: var(--primary_color);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  max-width: 90%;
}
.pm_mapping_check label{
  margin: 0;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
  white-space: nowrap;
  font-family: var(--inter_font);
}
.pm_checker_title{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
  font-family: var(--inter_font);
}
.pm_trade_flas_select.cmn_flag_select{
  height: 37px;
}
.cmn_flag_select ul{
  background-color: var(--body_bgcolor) !important;
  color: var(--white);
}
.cmn_flag_select ul li:hover{
  background-color: var(--switcher);
}
/* end of payment css */
/* security css */
.security_base_hint{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
.security_base_hint span{
  font-weight: var(--weight_four);
  font-size: var(--font_thirteen);
}
.security_card_provider{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
}
.security_card_head img{
  height: 25px;
  width: 25px;
}
.security_card_head_badge{
  background-color: var(--switcher);
  padding: 10px;
  width: fit-content;
}
.security_indicatorr{
  height: 13px;
  width: 13px;
  background-color: var(--primary_color);
}
.security_card_head_badge p{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.security_card_body h6{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_six);
  min-height: 40px;
}
.security_card_body p{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  min-height: 120px;
}
.security_card{
  background-color: var(--security_card);
}
.security_edit_btn{
  border: none;
  outline: none;
  pad: 5px 10px;
  color: var(--primary_color);
  background-color: transparent;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.sm_green_badge{
  background-color: var(--blur_primary);
}
.sm_green_badge svg{
  fill: var(--primary_color);
  height: 35px;
  width: 35px;
}
.sm_green_badge p{
  margin: 0;
  color: var(--primary_color);
  font-weight: var(--weight_four);
  font-size: var(--font_twelve);
}
.sm_inputs_label{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.sm_mobnum_select_holder{
  border: 1px solid var(--border_change);
  background-color: var(--body_bgcolor);
}
.sm_mobnum_select_holder .selected-flag:hover{
  background-color: transparent !important;
}
.sm_mobnum_select_holder .react-tel-input .country-list .country.highlight{
  background-color: var(--primary_color);
}
.sm_mobnum_select_holder .react-tel-input .form-control,.sm_mobnum_select_holder .react-tel-input .flag-dropdown{
  background-color: var(--body_bgcolor);
  border: none;
  color: var(--white);
  box-shadow: none;
}
.sm_mobnum_select_holder .react-tel-input .form-control{
  width: 100%;
  min-width: 100%;
  max-width: 100%;

}
.sm_mobnum_select_holder input{
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.sm_input_holderer{
  height: 45px;
}
.security_manage_btn{
  border: 1px solid var(--white);
  outline: none;
  background-color: var(--white);
  color: var(--body_bgcolor);
  padding: 5px 10px;
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
/* end of security css */
/* historypage css */
.hp_taber{
  cursor: pointer;
}
.hp_taber_text{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.hp_taber.hp_orange_tab{
  background-color: var(--profile_orange);
  border-top-left-radius: 5px;
}
.hp_taber.active{
  background-color: var(--primary_color);
}
.hp_small_text{
  margin: 0;
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_four);
}
.hp_primary_btn.primary_btn{
  font-size: var(--font_twelve);
  padding: 5px;
}
.hp_table_singlevalue{
  min-width: 50%;
}
.hp_table_singleRow{
  background-color: var(--history_tablerow);
}
.hp_selldata_bold{
  margin: 0;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
  min-height: 40px;
}
.hp_selldata_small{
  margin: 0;
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_four);
}
.hp_selldata_mid{
  margin: 0;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}
.hp_sellerdata_green.hp_selldata_mid{
  color: var(--primary_color);
}
.hp_sellerdata_green.hp_selldata_mid span{
  color: var(--market_sellTab);
}
.hp_selldata_table{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  background-color: var(--history_tablerow);
}
.hp_buydata_table{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  background-color: var(--history_tablerow);
}
/* end of historypae css */
/* api css */
.api_types_holder{
  background-color: var(--market_dropItem);
}
.api_types_title{
  margin: 0;
  color: var(--white);
  font-size: var(--font_twentyfive);
  font-weight: var(--weight_seven);
}
.api_types_scroller{
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.api_types_scroller :is(ul,li){
  color: var(--white);
  list-style-type: none;
  padding-left: 0;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  cursor: pointer;
}
.api_types_scroller :is(ul,li):hover{
  color: var(--primary_color);
}
.api_content_title{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.api_content_hint{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.api_content_scroller{
  max-height: 45vh;
  overflow-y: auto;
}
.api_content_holder{
  background-color: var(--api_content);

}
.api_types_scroller .accordion-flush .accordion-item .accordion-button,.api_types_scroller .accordion-flush .accordion-item .accordion-button.collapsed,.api_types_scroller .accordion-button,.api_types_scroller .accordion-item{
  background-color: transparent !important;
  border: none !important;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_six);
  font-family: var(--inter_font);
  padding: 0;
  box-shadow: none !important;
}
.api_types_scroller .accordion-body{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  font-family: var(--inter_font);
  padding: 0;
}
.api_types_scroller .accordion-button::after{
  filter: invert(0.7);
  background-size: 15px;
  background-position: center;
}
/* end of api css */

/* convert css */
.swiper_textConts{
  background-color: #14141487;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.convert_swiper_title{
  margin: 0;
  width: 70%;
  color: #fff;
  font-size: var(--font_thirtyfive);
  font-weight: var(--weight_eight);
}
.convert_swiper_hint{
  margin: 0;
  width: 70%;
  color: #fff;
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.convert_swiper_img{
  min-width: 100%;
  min-height: 350px;
  max-height: 350px;
  object-fit: cover ;
}
.convert_swiper_singleSlide{
  cursor: pointer;
}
.conver_swiper_holder .swiper{
  padding-bottom: 80px !important;
}
.conver_swiper_holder .swiper-pagination{
  bottom: 60px !important;;
}
.conver_swiper_holder .swiper-pagination-bullet-active{
  background-color: var(--primary_color) !important;
}
.conver_swiper_holder .swiper-pagination-bullet{
  background-color: var(--swiper_bullet);
}
.conver_bottom_card{
  background-image: var(--stable_gradient);
  border: 1px solid var(--border_change);
}
.convert_hr{
  background-color: var(--conver_hr);
  height: 1px;
  width: 100%;
}
.convert_divider_coinName{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.convert_doubleStrike{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);

}
.convert_doubleStrike span{
  text-decoration: line-through double;
}
/* end of convert css */
/* inquiry css */
.inquiry_cc_img{
  height: 120px;
  width: 120px;
  min-height: 120px;
  min-width: 120px;
  max-height: 120px;
  max-width: 120px;
}
.inquiry_left_hint{
  margin: 0;
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_seven);
}
.inquiry_right_textAlign{
  max-width: 60%;
  width: fit-content;
}
.inquiry_right_hint{
  margin: 0;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.inquiry_blackBox_holder{
  background-color: var(--history_tablerow);
}
.inquiry_blackBox{
  background-color: var(--homecard_bgcolor);
  min-height: 350px;
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 100%;
  max-width: 100%;
}
.cm_editors_hint{
  margin: 0;
  color: var(--white);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
  cursor: pointer;
}
.im_textarea_holder.sm_mobnum_select_holder{
  height: unset;
}
.im_textarea{
  background-color: transparent ;
  border: none;
  outline: none;
  resize: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
.im_textarea_count{
  margin: 0;
  color: var(--placeholder);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.im_uploadImg{
  height: 55px;
  width: 55px;
  min-height: 55px;
  min-width: 55px;
  max-height: 55px;
  max-width: 55px;
}
.im_fileInp{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  opacity: 0;

}
.im_captcha_holder canvas{
  background: var(--walletcard_bg) !important;
  width: 90px !important;
  height: 30px;

}
.im_captcha_typerInp{
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  max-width: 100%;
  letter-spacing: 2px;
}
.im_captcha_typer{
  background: var(--walletcard_bg) !important;
  border: 1px solid var(--speaker_border);
  width: 90px ;
  max-width: 90px;
  height: 31px;
}
.im_captcha_flower{
  position: absolute;
  right: 2%;
  top: -5%;
  color: var(--red);
  font-size: var(--lower_fontsize);
}
.im_speaker_btn{
  border: 1px solid var(--speaker_border);
  border: none;
  background-color: var(--walletcard_bg);
  height: 31px;
}
.im_speaker_btn img{
  height: 15px;
  width: 15px;
  position: relative;
  top: -3px;
}
#reload_href{
  font-size: var(--font_twelve);
  margin: 0;
  position: absolute;
  top: 35px;

}
.im_small_hint{
  margin: 0;
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_four);
}
.im_time_text{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.im_action_text{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  cursor: pointer;
}
.im_inquiry_redText{
  color: var(--red);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.im_inquiry_box{
  background-color: var(--profile_faqbox);
}
.im_inquiryAnswer_box{
  background-color: var(--inquiry_answerBox);
}
/* end of inquiry css */

/* edit profile css */
.editprfl__userId{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.sm_mobnum_select_holder.editprfl__mob_input .react-tel-input .form-control, .sm_mobnum_select_holder.editprfl__mob_input .react-tel-input .flag-dropdown,.sm_mobnum_select_holder.editprfl__mob_input{
  background-color: var(--stable_bottomGradient);
  color: var(--white);
}
.sm_mobnum_select_holder.editprfl__mob_input .react-tel-input .country-list{
  background-color: var(--stable_bottomGradient);
  color: var(--white);
}
.sm_mobnum_select_holder.editprfl__mob_input .react-tel-input .country-list .country:hover{
  background-color: var(--cmn_modal_closer);
}
.editprfl__subs_date{
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
}

/* end of edit profile css */

/* create offer css */
.createOffer__progress_holder{
  background-color: var(--profile_faqbox);
}
.createOffer__hint_indicator{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  white-space: nowrap;
}
.createOffer__hint_indicator_active{
  color: var(--primary_color);
}
.createOffer__progress_bar{
  height: 3px;
  width: 74%;
  background-color: var(--white);
  position: absolute;
  top: 33%;
  left: 13%;
}
.createOffer__select_coinImg.market_coinname_img{
  left: 3%;
}
.market_drophint.createOffer__select_dropHint{
  right: 3%;
}
.createOffer_steps_title{
  color: var(--white);
  font-size: var(--font_sixteen);
  font-weight: var(--weight_five);
}
.createOffer_steps_hint{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.createOffer__tc_title{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.createOffer__tc_tab{
  background-color: var(--profile_faqbox);
  cursor:pointer;
}
.createOffer__tc_tab_active{
  background-color: var(--primary_color);
  cursor:pointer;
}
.createOffer__tab_img{
  height: 30px;
  width: 30px;
}
.createOffer__tab_title{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
}
.createOffer__tab_hinter{
  color: var(--white);
  font-size: var(--lower_fontsize);
  font-weight: var(--weight_four);
}
.createOffer__borbot{
  border-bottom: 1px solid var(--createOffer_borbot);
}
.createOffer__tp_inputGroup{
  border: 1px solid var(--border_change);
}
.createOffer__tp_inpgrp_btn{
  border: none;
  outline: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  background-color: transparent;
  padding: 10px 20px;
}
.createOffer__tp_inpgrp_btn:hover{
  background-color: var(--profile_faqbox);

}
.createOffer__tp_inpgrp_input{
  border: none;
  outline: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  background-color: transparent;
  padding: 10px;
  box-shadow: none;
  width: 100%;
  max-width: 100%;
  border-left: 1px solid var(--border_change);
  border-right: 1px solid var(--border_change);
}
.createOffer__tp_percent{
  border: none;
  outline: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_five);
  background-color: var(--border_change);
  padding: 10px 15px;
  box-shadow: none;
}
.createOffer__tp_inpgrp_title{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
}
.createOffer__ti_offersHolder{
  border: 1px solid var(--border_change);
  max-height: 70px;
  overflow-y: auto  ;
}
.createOffer_ti_offer{
  border: none;
  outline: none;
  background-color: var(--createOffer_offer);
  color: var(--black);
  font-size: var(--font_twelve);
  font-weight: var(--weight_four);
  padding: 3px 10px;
  min-width: 90px;
}
.createOffer_ti_offer span{
  cursor: pointer;
  
}
.createOffer_ti_textarea{
  border: 1px solid var(--border_change);
  outline: none;
  box-shadow: none;
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
  background-color: var(--stable_bottomGradient);
}
.createOffer__ti_check_label.createacc_checklabel{
  font-weight: var(--weight_four);
}
.createOffer__select_label.market_select_label{
  font-size: var(--font_fifteen);
}
/* end of create offer css */

/* create offerlist css */
.offerList__title{
  color: var(--white);
  font-size: var(--font_eighteen);
  font-weight: var(--weight_six);
}
.offerList__hint{
  color: var(--white);
  font-size: var(--font_thirteen);
  font-weight: var(--weight_four);
}
.createOfferList_btn:hover img{
  filter: invert(1);
}
/* end of create offerlist css */

/* extra css */
.cmnModal__securityCodeCopier{
  background-color: var(--sidebar_bodyBg);
}
.secrutiry__address{
  color: var(--white);
  font-size: var(--font_thirteen);
}
.withdraw__secTaber{
  color: var(--white);
  font-size: var(--font_fifteen);
  font-weight: var(--weight_five);
  border-bottom: 2px solid transparent;
}
.withdraw__secTaber.active{
  border-bottom: 2px solid var(--white);
}
.withdraw__intransitTaber{
  min-width: 30%;
  cursor: pointer;
}
.withdraw__manageText{
  color: var(--primary_color);
  font-size: var(--font_twelve);
  font-weight: var(--weight_five);
}
/* end of extra css */
/* media query */

@media (width > 1750px) {
  .home_bannerLeft h4 {
    font-size: var(--font_fifty);
  }
  .common_spacer {
    padding: 50px;
  }
  .home_paxText {
    font-size: var(--font_thirtyfive);
  }
  .commitment_card_desc,
  .enterprice_desc {
    font-size: var(--font_eighteen);
  }
  .home_section_title {
    font-size: var(--higher_fontsize);
  }
  .walletCard_title {
    font-size: var(--font_twentyfive);
  }
  .walletCard_img {
    height: 250px;
    width: 250px;
    top: -60%;
    left: 24%;
  }
  .helpCard_img {
    height: 250px;
    width: 250px;
    top: -32%;
    left: 36%;
  }
  .business_hint {
    font-size: var(--font_thirtyfive);
  }
  .home_blueTitle {
    font-size: var(--font_fourty);
  }
  .home_helpCard {
    min-height: 350px;
  }
  .home_stepHint {
    font-size: var(--font_fifteen);
  }
  .footer_gridHolder {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .footer_linksTitle {
    font-size: var(--font_twenty);
  }
  .footer_links li,
  .footer_links li a {
    font-size: var(--font_sixteen);
  }
  .hp_buydata_table{
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  
}
@media (width > 1700px){
  .header_navlinks.main_header_navlinks li, .header_navlinks.main_header_navlinks li a{
    font-size: var(--font_fifteen);
  }
  .header_navlinks.main_header_navlinks li a{
    padding: 5px 20px;
  }
  .header_navlinks{
    gap: 10px;
  }
}
@media (1500px < width < 1750px) {
  .home_bannerLeft h4 {
    font-size: var(--font_thirtyfive);
  }
  .home_section_title {
    font-size: var(--higher_fontsize);
  }
  .common_spacer {
    padding: 40px;
  }
  .home_paxText {
    font-size: var(--higher_fontsize);
  }
  .enterprice_capsHint {
    font-size: var(--font_twentyfive);
  }
  .enterprice_desc,
  .enterprice_desc {
    font-size: var(--font_eighteen);
  }
  .commitment_card_desc {
    font-size: var(--font_fifteen);
  }
  .walletCard_title {
    font-size: var(--font_twentyfive);
  }
  .walletCard_img {
    height: 200px;
    width: 200px;
    top: -40%;
  }
  .helpCard_img {
    height: 250px;
    width: 250px;
    top: -32%;
    left: 30%;
  }
  .business_hint {
    font-size: var(--font_thirtyfive);
  }
  .home_blueTitle {
    font-size: var(--font_fourty);
  }
  .home_helpCard {
    min-height: 350px;
  }
  .home_stepHint {
    font-size: var(--font_fifteen);
  }
  .footer_gridHolder {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .footer_linksTitle {
    font-size: var(--font_twenty);
  }
  .footer_links li,
  .footer_links li a {
    font-size: var(--font_fifteen);
  }
  .hp_buydata_table{
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}

@media(width > 1399px){
  .right_rectangle,.center_right_rectangle{
    right: -9%;
  }
  .center_left_rectangle,.left_rectangle{
    left: -9%;
  }
  
}
@media (width >= 1200px){
  .rec_single_set{
    min-height: 60px;
  }
}
@media (1200px < width < 1500px ){
  .hp_selldata_table{
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  }
}
@media (width >= 1500px){
  .commitment_card{
    min-height: 250px;
  }
}
@media (width <= 1500px){
  .hp_selldata_bold{
    min-height: unset;
  }
}
@media (width < 1200px){
  .custom_container{
    max-width: 100% !important;
  }
}
@media (1200px <= width <= 1300px){
.header_navlinks.main_header_navlinks li, .header_navlinks.main_header_navlinks li a{
  font-size: var(--font_eleven);
}
}
@media (991px < width < 1200px){
  .mt_deposit_div h6, .mt_remitt_div h6{
    font-size: var(--font_fifteen);
  }
  .spot .autotrade_pnl .trans_his{
    padding:10px
  }
  .spot .autotrade_pnl .analysis{
    padding: 5px 8px;
  }
  .hp_selldata_table{
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  .sidebar_canva_shower{
    top:110px;
    left: 0;
  }
  .sidebar_canva_burg{
    font-size: var(--font_thirteen);
    padding: 10px 10px 7px 10px;
    z-index: 100;
  }
}
@media (width > 991px) {
  /* .fit_vh_section{
        height: 100vh;
        min-height: 100vh;
        max-height: 100vh;
    } */
    .sd_favourite_holder{
      right: 3%;
    }
    .storeReg__primaryInp{
      max-width: 100%;
    }
}
@media (width < 991px) {
  .table_triple_grad {
    display: none;
  }
  .sm_devicemanage_head{
    border: none;
  }
  .mailver_backBtn{
    top: 2%;
  }
 
}
@media (width > 768px){
  .prfl_imgUpload_hint{
    min-height: 60px;
  }
}
@media (768px <= width < 991px) {
  .home_helpCard {
    min-height: 290px;
  }
  .right_rectangle {
    right: -15%;
    top: 42%;
  }
  .home_hr_connector {
    top: -17%;
  }
  .center_right_rectangle {
    right: -15%;
    top: 41%;
  }
  .center_left_rectangle {
    left: -14%;
    top: 41%;
  }

  .left_rectangle {
    left: -14%;
    top: 42%;
  }
  .mt_tiny_datetimer{
    font-size: var(--font_thirteen);
  }
  .sidebar_canva_shower{
    top:130px;
  }
  .market_coinname_img.sd_store_searchImg{
    left: 6%;
  }
  .assets .total_bal{
    gap: 5px;
  }
  .assets .balance{
    font-size: var(--font_thirteen);
  }
  .assets .custom_usd_drop button{
    font-size: var(--font_twenty);
    padding-left: 0;
  }
  .hp_selldata_table{
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .inquiry_right_textAlign{
    width: 90%;
    max-width: 90%;
  }
  .sidebar_canva_shower{
    top:110px;
    left: 0;
  }
  .sidebar_canva_burg{
    font-size: var(--font_thirteen);
    padding: 10px 10px 7px 10px;
  }
  .convert_swiper_title{
    width: 80%;
    font-size: var(--font_twentyfive);
  }
  .convert_swiper_hint{
    width: 80%;
    font-size: var(--font_twelve);
  }
  .convert_swiper_img{
    height: 250px;
    min-height: 250px;
    max-height: 250px;
  }
  .prodReg__topTaber{
    width: 25%;
  }
}
@media(768px <= width < 900px){
  .assets .donut_chart_sec .header{
    flex-direction: column;
  }
  .assets_space_aligner{
    margin-bottom: 15px !important;
  }
}
@media (575px <= width < 768px) {
  .commitment_card {
    min-height: 190px;
  }
  .home_stepsHolder {
    padding: 20px;
  }
  .right_rectangle {
    right: -16%;
    top: 47%;
    height: 15px;
    width: 15px;
  }
  .home_hr_connector {
    top: -17%;
  }
  .center_right_rectangle {
    right: -15%;
    top: 46%;
    height: 15px;
    width: 15px;
  }
  .center_left_rectangle {
    left: -14%;
    top: 46%;
    height: 15px;
    width: 15px;
  }

  .left_rectangle {
    left: -14%;
    top: 47%;
    height: 15px;
    width: 15px;
  }
  .banner_content h1 {
    font-size: var(--higher_fontsize);
  }
  .walletCard_img {
    transform: translateX(30%);
  }
  .home_blueTitle,
  .home_paxText,
  .business_hint {
    font-size: var(--font_twenty);
  }
  .sidebar_canva_shower{
    top:110px;
    left: 0;
  }
  .sidebar_canva_burg{
    font-size: var(--font_thirteen);
    padding: 10px 10px 7px 10px;
  }
  .market_coinname_img.sd_store_searchImg{
    left: 5%;
  }
  .assets .custom_usd_drop button{
    font-size: var(--font_twenty);
    padding-left: 0;
  }
  .custom_tabs .tabs_heading,.tabs_heading_active{
    font-size: var(--font_thirteen);
    padding: 7px 12px !important;
    flex: 1;
  }
  .hp_selldata_table{
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .inquiry_right_textAlign{
    width: 90%;
    max-width: 90%;
  }
  .convert_swiper_title{
    width: 80%;
    font-size: var(--font_twenty);
  }
  .convert_swiper_hint{
    width: 80%;
    font-size: var(--font_twelve);
  }
  .convert_swiper_img{
    height: 250px;
    min-height: 250px;
    max-height: 250px;
  }
    .mk__coloredTaberRed,.mk__coloredTaberPrimary,.mk__chooseCoinTitle{
    font-size: var(--font_sixteen);
  }
  .prodReg__topTaber{
    width: 30%;
  }
  
  .prodReg__topTaber p{
    font-size: var(--font_thirteen);
  }
 
  
}
@media (width > 768px){
  .notices_searcher{
    width: unset !important;
  }
}
@media (width <= 767px) {
  .home_benefits_card p {
    max-width: 100%;
    font-size: var(--font_thirteen);
  }
  .security_card_body h6,.security_card_body p{
    min-height: unset;
  }
  .si_table_prodImg{
    height: 60px;
    width: 60px;
  }
 
}
@media (575px < width < 1199px ){
  
  .rec_single_set{
    min-height: 40px;
  }
}
@media (width < 575px) {
  .market_coinname_select .market_coinname_img{
    left: 3%;
  }
  .walletCard_img {
    width: 120px;
    height: 120px;
    top: -20%;
  }
  .business_hint {
    font-size: var(--font_eighteen);
  }
  .home_businessSec {
    background-position: right;
  }
  .home_stepsHolder {
    max-width: 120px;
  }
  .home_banner {
    background-position: right;
  }
  .banner_content h1 {
    font-size: var(--font_twentyfive);
  }
  .primary_connetBtn {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    padding: 0px;
  }
  .primary_connetBtn img {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
  }
  .home_steps_mobhint {
    font-size: var(--font_thirteen);
  }
  .home_bannerLeft h4 {
    font-size: var(--font_twenty);
  }
  .home_helpCard {
    padding: 10px;
  }
  .footer_copyright_holder p {
    font-size: var(--font_thirteen);
  }
  .home_section_title {
    font-size: var(--font_fifteen);
  }
  .home_paxText,
  .home_blueTitle {
    font-size: var(--font_eighteen);
  }
  .common_spacer {
    padding: 15px;
  }
  .home_helpCard p {
    max-width: 90%;
  }
  .login_check_label {
    font-size: var(--lower_fontsize);
  }
  .login_cont_holder p {
    font-size: var(--font_thirteen);
  }
  .sd_seller_dtls h6 {
    font-size: var(--font_fifteen);
  }
  .sd_seller_name,
  .sd_product_dtls span {
    font-size: var(--lower_fontsize);
  }
  .sd_product_dtls p {
    font-size: var(--font_thirteen);
  }
  .market_payment_btn {
    padding: 7px 20px;
    font-size: var(--font_twelve);
    min-width: unset;
  }
  .market_tv_title {
    font-size: var(--font_thirteen);
  }
  .market_tv_userImg {
    height: 30px;
    width: 30px;
  }
  .user_activity_online_indicator,
  .user_activity_offline_indicator {
    height: 8px;
    width: 8px;
  }
  .vendor_badge{
    bottom: -80%;
    left: 37px;
  }
  .mt_report_prblmBtn{
    min-width: unset !important;
    width: fit-content !important;
    padding: 5px !important;
    font-size: var(--font_thirteen);
  }
  .mt_usdt_leftHead,.mt_tiny_datetimer{
    font-size: var(--font_thirteen);
  }
  .mt_coin_batch img{
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    max-height: 20px;
    max-width: 20px;
  }
  .mt_coin_batch{
    font-size: var(--font_thirteen);
  }
  .mt_seller_transact_side, .mt_seller_quantity_side{
    padding: 10px;
  }

  .mt_seller_personImg{
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    max-height: 20px;
    max-width: 20px;
  }
  .mt_remitt_div,.mt_deposit_div{
    width:100%;
    height: 50px;
  }
  .si_msgcnt_holderLeft p, .si_msgcnt_holder p{
    font-size: var(--font_thirteen);
  }
  .si_received_msg{
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .si_received_msgtime{
    font-size: var(--lower_fontsize);
  }
  .si_chatbox_scroller{
    height: 300px;
    max-height: 300px;
  }
  .mt_redgradient_box{
    padding-left: 15px !important;
  }
  .withdraw_content .step_3 .withdrawal_amt{
    flex-direction: column;
    align-items: flex-start ;
  }
  .hp_selldata_table{
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .hp_selldata_singlecell{
    min-width: 80%;
    place-self: center;
  }
  .hp_taber{
    flex: 1;
  }
  .inquiry_right_textAlign{
    width: 100%;
    max-width: 100%;
  }  
  .storeReg_mobBtn{
    padding: 7px;
    min-width: 60px;
  }
  .mk__coloredTaberRed,.mk__coloredTaberPrimary,.mk__chooseCoinTitle{
    font-size: var(--font_thirteen);
  }
  .mk__incDec{
    min-width: 90%;
    max-width: 90%;
  }
  .mk__sellRegBtn,.mk__buyPrimaryBtn{
    font-size: var(--font_fifteen);
  }
  .prodReg__topTaber{
    width: 33%;
  }
  
  .prodReg__topTaber p{
    font-size: var(--lower_fontsize);
    font-weight: var(--weight_six);
  }
  .prodReg__productTaber{
    padding: 7px;
  }
  .prodReg__productTaber p{
    font-size: var(--lower_fontsize);
  }
 
  
}
@media (450px < width < 575px) {
  .walletCard_img {
    transform: translateX(25%);
  }
  .sd_card_prodImg {
    height: 65px;
    width: 65px;
    min-height: 65px;
    min-width: 65px;
    max-height: 65px;
    max-width: 65px;
    object-fit: contain;
  }
  .market_coinname_img.sd_store_searchImg,.sd_store_coingImg.market_coinname_img{
    left: 2%;
  }
  .custom_tabs .tabs_heading,.tabs_heading_active{
    font-size: var(--font_thirteen);
    padding: 8px 10px !important;
    flex: 1;
  }
  .tabs_head_cnt{
    gap: 10px;
  }
  .prfl_gray_ttile,.prfl_gray_ttile.prfl_red_ttl,.prfl_gray_ttile.prfl_green_ttl{
    font-size: var(--font_fifteen);
  }
  .sidebar_canva_shower{
    left: 0;
  }
  .sidebar_canva_burg{
    font-size: var(--font_twelve);
    padding: 7px 7px 3px 7px;
  }
  .convert_swiper_title{
    width: 90%;
    font-size: var(--font_sixteen);
  }
  .convert_swiper_hint{
    width: 90%;
    font-size: var(--lower_fontsize);
  }
  .convert_swiper_img{
    height: 220px;
    min-height: 220px;
    max-height: 220px;
  }
  .storemanager__title.reg_stats_shower{
    font-size: var(--font_eighteen);

  }
}
@media (width <= 450px) {
  .walletCard_img {
    transform: translateX(15%);
  }
  .footer_copyright_holder p {
    font-size: var(--small_font);
  }
  .sd_card_prodImg {
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px;
    max-height: 50px;
    max-width: 50px;
    object-fit: contain;
  }
  .mt_Seller_indication_hint{
    height: 15px;
    width: 15px;
  }
  .mt_timermid_countdown span{
    font-size: var(--font_thirtyfive);
  }
  .sidebar_canva_shower{
    top:65px;
    left: 0;
  }
  .market_coinname_img.sd_store_searchImg{
    left: 4%;
  }
  .sd_store_coingImg.market_coinname_img{
    left: 3%;
  }
  .details_list .withdraw{
    font-size: var(--font_fifteen);
  }
  .recent_activity h5{
    font-size: var(--font_eighteen) !important;
  }
  .custom_tabs .tabs_heading,.tabs_heading_active{
    font-size: clamp(var(--lower_fontsize),var(--lower_fontsize),var(--font_fifteen));
    padding: 5px 8px !important;
    flex: 1;
    width: 100%;
  }
  .tabs_head_cnt{
    gap: 7px;
  }
  .convert_swiper_title{
    width: 90%;
    font-size: var(--font_fifteen);
  }
  .convert_swiper_hint{
    width: 90%;
    font-size: var(--lower_fontsize);
  }
  .convert_swiper_img{
    height: 220px;
    min-height: 220px;
    max-height: 220px;
  }
  .reg_stats_shower{
    font-size: var(--font_eighteen);
  }
  .storemanager__title.reg_stats_shower{
    font-size: var(--font_fifteen);

  }
  .reg_mobqr,.reg_qrcoder{
    height: 100px;
    width: 100px;
    min-height: 100px;
    min-width: 100px;
    max-height: 100px;
    max-width: 100px;
  }
  .reg_qrcode_btn{
    font-size: var(--lower_fontsize);
  }
  .reg_url_holder p{
    font-size: var(--lower_fontsize);
  }
  .deposit .fst_sec{
    padding: 10px !important;
  }
  .createOffer__hint_indicator{
    font-size: var(--small_font);
  }

}
@media (width < 400px){
  .sc_labeltext,.sc_coinName{
    min-height: 45px;
  }
  .common_spacer{
    padding: 5px;
  }
  .prfl_gray_ttile,.prfl_gray_ttile.prfl_red_ttl,.prfl_gray_ttile.prfl_green_ttl{
    font-size: var(--font_thirteen);
  }
  .hp_taber_text{
    font-size: var(--lower_fontsize);
  }
  .assets .donut_chart_sec .header{
    flex-direction: column;
  }

  
}
/* end of media query */

/* hari css */
.market_offcanva.offcanvas.offcanvas-end {
  width: 650px !important;
  top: unset !important;
  bottom: 0 !important;
  height: 80% !important;
  border-radius: 16px 16px 0px 0px;
}
.market_offcanva .offcanvas-header {
  background-color: var(--walletcard_bg) !important;
  border-radius: 14px 14px 0px 0px;
}
.offcanvas-title {
  color: #ffffff;
  font-size: 15px;
}
.market_offcanva .offcanvas-body {
  background-color: var(--market_tablecanva) !important;
  border-radius: 10px 10px 0px 0px;
}
.market_offcanva .offcanvas-title {
  color: var(--white);
}
.btn-close {
  filter: invert(1) !important;
  font-size: 10px;
}
.MuiPaper-root-MuiDrawer-paper,
.MuiDataGrid-footerContainer,
.MuiDataGrid-root,
.MuiDataGrid-row,
.MuiDataGrid-column,
.MuiDataGrid {
  border: 0 !important;
}
.MuiDataGrid-withBorderColor {
  border-color: transparent !important;
}
.MuiDataGrid-columnHeaderTitle {
  color: #e3e3e3;
  font-size: 14px;
}
.MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer {
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
}
.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}
.MuiDataGrid-menuIcon,
.MuiDataGrid-iconSeparator,
.MuiTablePagination-root,
.MuiDataGrid-selectedRowCount {
  display: none !important;
}
.MuiDataGrid-sortIcon {
  fill: #00caba !important;
}
.MuiDataGrid-cellContent {
  color: #f1f1ff !important;
  font-size: 12px !important;
}
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-cell:focus {
  outline: solid transparent 1px !important;
}
.MuiDataGrid-row:hover,
.MuiDataGrid-row.Mui-selected {
  background-color: transparent !important;
}
.btc {
  width: 100%;
  display: flex;
  align-items: center;
}
.btc p {
  color: #fff;
  font-size: 12px;
}
.btc img {
  width: 26px;
  height: 26px;
  object-fit: contain;
  aspect-ratio: 3/2;
}
div[title="-23.13"]:nth-child(1) {
  color: red !important;
}
